import React, { Component } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import default_menu from '../../images/default_menu.svg';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
import { Prompt } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { Link } from 'react-router-dom';
import { getApi } from '../../Services/GetAPI';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';
import deletebtn from '../../images/close-button.svg';
import IMG_NOTE_ICON from '../../images/notes.svg';
import adduser from '../../images/ic-user.png';
import PDF_ICON from '../../images/pdf.png';
import IMG_ICON from '../../images/image.png';

import {
  getFormattedDate,
  formatDate,
  getApiAsyn,
  loadierShow,
  baseUrls,
  loadingsShow,
  getApiAsynBlobImage,
} from '../../Services/PostAPI';
import { post, put } from 'axios';
import dragicon from '../../images/dragicon.png';
import deletebtnred from '../../images/close_button_red.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CreateCustomer from '../../Customers/CustomerAndBrokers/CreateCustomer';
import { Popup } from '../../common/Popup';
import NotificationMessage from '../../common/NotificationMessage';
import { PopupLocConfirm } from '../../common/PopupLocConfirm';
import Autosuggestion from '../../common/Autosuggestion';
import { Badge } from 'primereact/badge';
import { API_URL } from '../../constants/urlConstants';
import LoctionName from '../LoctionName';
import { PopupRefConfirm } from '../../common/PopupRefConfirm';

const cookies = new Cookies();
let istSearchList = '';
let bool = 0;
let isNotify = false;
let FileData = [];
class CreateOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      orderValue: {
        total: 0,
        order_date: new Date(),
        load_type: 'FL',
        csa_compliant: 'false',
        frt_cost_per_mile: 0,
        miletcaltype: 'Auto',
        extra_cost: 0,
        custom_surcharge: 0,
        invoice: 0,
        tax_total: 0,
        urgent: "No"
      },
      oderlist: [],
      isErrorInLocationModal: false,
      PromptPrevent: false,
      Place: [],
      ImageAddedS3: [],
      formOption: {},
      isTripEditable: false,
      orderItemModal: false,
      locationModal: false,
      currentBoxAddress: false,
      tax_label: '',
      qtytotal: 0,
      qtytotalC: 0,
      lentotal: 0,
      weighttotal: 0,
      countryList: [],
      stateList: [],
      cityList: [],
      currencyList: [],
      defaultAssetList: [],
      revenueList: [],
      taxList: [],
      cntryId: '',
      stateId: '',
      errors: {},
      milestotal: '',
      LocationData: [],
      fileurl: adduser,
      consignValue: [],
      uuidval: "",
      dispatch_byContact: {},
      sales_byContact: {},
      brokerContact: {},
      CustomerDropdonw: [],
      DispatcherDropdown: [],
      BrokerDropdown: [],
      SalesDropdown: [],
      CurDropdown: [],
      OrderDropdonw: [],
      RateDropdonw: [],
      ORDER_ITEM_STATUSDropDonw: [],
      TrapDropdonw: [],
      OrderStatusDropdonw: [],
      PackgDropdonw: [],
      UnitDropdonw: [],
      FuelSurchageDropdonw: [],
      ReatMethodDropdonw: [],
      ConfrimRefConfirmation: false,
      APPNT_TYPEDropdown: [],
      EQUIP_TYPEDropdown: [],
      COMM_TYPEDropdown: [],

      showCustomerModal: false,
      showDeleteModal: false,
      deleteItemIndex: '',
      textMessage:
        'This will delete all the items of related order. Do you want to continue ?',
      orderItem: '',
      ImageDisplay: [],
      CSModal: false,
      CSConfirm: false,
      CSMessage: '',
      showConfirmation: false,
      showRefConfirmation: false,
      confirmationMessage: '',
      confirmationRfMessage: '',
      isConfirmation: false,
      rmethods: '',
      selectedLocation: '',
      locationsStoredInSystem: []
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.WrapperRef = node;
  }
  handleClickOutside(event) {
    this.setState({
      bool: false,
    });
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      alert('You clicked outside of me!');
    }
  }

  getAutosuggest = async (id) => {
    let ddbrokder = [];
    if (id !== undefined && id.length > 2) {
      let res = await getApiAsyn(
        `customers?intent=suggest&begin-with=${id}`,
        cookies.get('authorization')
      );
      if (res && res.length > 0) {
        if (res.length > 0) {
          this.setState({
            bool: true,
          });
          ddbrokder = res;
          ddbrokder = ddbrokder.map((item) => ({
            name: '(' + item.id + ') ' + item.name,
            value: item.id,
            currency_id: item.currency_id,
            broker: item.broker,
            email: item.email,
            cont_name: item.cont_name,
            phone: item.phone,
          }));
          this.setState({
            CustomerDropdonw: ddbrokder,
          });
        } else {
          this.setState({
            CustomerDropdonw: [],
          });
        }
      } else {
        this.setState({
          CustomerDropdonw: [],
        });
      }
    }
  };

  setAutosuggestId = (id, name) => {
    this.setState({
      orderValue: {
        ...this.state.orderValue,
        billed_to: id,
        billed_to_name: name,
      },
      bool: false,
    });

    this.CalcCurrencies(id);
  };

  handleChangeGenMile = (event) => {
    this.setState({
      milestotal: event.target.value,
    });
  };
  removeOrderItem = (index, item) => {
    this.setState({
      deleteItemIndex: index,
      orderItem: item,
      showDeleteModal: true,
    });
  };
  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  toggleAddCustomerModal = () => {
    this.setState((prevState) => ({
      showCustomerModal: !prevState.showCustomerModal,
    }));

    const { showCustomerModal } = this.state;
    if (showCustomerModal === true) {
      this.getCustomerData();
    }
  };


  toggleOrderItemNoteModal = (index) => {
    this.setState({
      orderItemModal: index,
    });
  };
  toggleLocation = (index) => {
    const { consignValue } = this.state;
    if (index !== false && typeof index === 'number') {
      bool = index;
    }
    if (index === false && consignValue[bool].postal_code === '') {
      this.setState((prevState) => {
        const { consignValue } = prevState;
        for (let i = 0; i < consignValue.length; i++) {
          consignValue[bool].location = '';
        }

        return { consignValue };
      });
    }
    this.setState({
      locationModal: index,
    });
  };
  toggleLocationModal = (index, type) => {
    const { consignValue } = this.state;
    if (index !== false && typeof index === 'number') {
      bool = index;
    }
    if (index === false && !consignValue[bool].city) {
      this.handelMessage('City is required', 'errorBoll');
      this.setState({ isErrorInLocationModal: true });
    } else if (index === false && consignValue[bool].postal_code === '') {
      this.handelMessage('Postal code is required', 'errorBoll');
      this.setState({ isErrorInLocationModal: true })
    } else {
      this.setState({
        locationModal: index,
        selectedLocation: consignValue[index]?.loc_nm,
        uuidval: uuid()
      });
      if (index === false && type === "save") {
        this.handleCallMiles();
      }
    }
  };
  getPlace = async (place) => {
    const { uuidval } = this.state;

    let res = await getApiAsyn(
      `${API_URL.LOCATION.PLACE_AUTOCOMPLETE}?search=${place}&uuid=${uuidval}`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      let address = res.map((item, i) => ({
        id: i,
        formatted_address: item.description,
        place_id: item.place_id,
      }));
      this.setState({
        Place: address,
      });
    }
  };
  setAddress = async (val, id) => {
    const { locationModal, consignValue, uuidval } = this.state;

    let res = await getApiAsyn(
      `${API_URL.LOCATION.PLACE_DETAIL}?placeid=${id}&uuid=${uuidval}`,
      cookies.get('authorization')
    );
    let ds = res.result && res.result.address_components;
    let pscode = ds.filter((x) => x.types[0] === 'postal_code');

    let provincecity = ds.find((x) => x.types[0] === 'locality')?.long_name;
    let province = ds.find((x) => x.types[0] === 'administrative_area_level_1')?.short_name;
    if (pscode.length !== 0) {
      consignValue[locationModal].location = val;
      consignValue[locationModal].postal_code = pscode[0].long_name;
      consignValue[locationModal].city = provincecity ?? provincecity;
      consignValue[locationModal].province = province ?? province;
      this.setState({
        consignValue,
      });
      this.setState({
        currentBoxAddress: false,
      });
    } else {
      this.setState({
        currentBoxAddress: false,
      });
      this.handelMessage('Invalid address postal code is missing', 'errorBoll');
    }
  };
  handleReset = () => {
    window.location.reload();
  };
  onChangeOrderItem = (index) => (e) => {
    const { consignValue } = this.state;
    if (e.target.name === 'loc_nm') {
      this.setState({
        selectedLocation: e?.value
      })
      const { name, location, city, province, postal_code } = e.value;
      consignValue[index]["loc_nm"] = e?.value.name !== undefined ? name : e?.value;
      consignValue[index]["location"] = location !== undefined ? location : consignValue[index]["location"];
      consignValue[index]["province"] = province !== undefined ? province : consignValue[index]["province"];
      consignValue[index]["city"] = city !== undefined ? city : consignValue[index]["city"];
      consignValue[index]["postal_code"] = postal_code !== undefined ? postal_code : consignValue[index]["postal_code"];
      this.setState({
        consignValue,
        PromptPrevent: true,
      });
      return
    }
    if (e.target.name === 'location' && e.target.value.length > 2) {
      this.getPlace(e.target.value);
      this.setState({
        currentBoxAddress: true,
      });
    }
    if (e.target.name === 'location' && e.target.value.length < 2) {
      this.setState({
        currentBoxAddress: false,
      });
    }
    consignValue[index][e.target.name] = e.target.value;
    // if (e.target.name === 'city') {
    //   this.setState({
    //     isErrorInLocationModal: !e.target.value.length
    //   });
    // }
    this.setState({
      consignValue,
      PromptPrevent: true,
    });
  };

  getDataByOrderID = async () => {
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        loadingsShow('block');
        let res = await getApiAsyn(
          `orders/${this.props.match.params.id}`,
          cookies.get('authorization')
        );
        if (res) {
          if (res.files && res.files.length > 0) {
            this.getFileData(res.files);
          }
          if (res.dispatch_by !== undefined && res.dispatch_by != null) {
            this.getDispatchByCalc(res.dispatch_by);
          }
          if (res.broker !== undefined && res.broker != null) {
            this.getBrokerByCalc(res.broker);
          }
          if (res.sales_by !== undefined && res.sales_by != null) {
            this.getSalesByCalc(res.sales_by);
          }
          let qtyval = [],
            lenval = [],
            weightval = [],
            qtyvalC = [];
          this.setState((prevState) => {
            let {
              orderValue,
              consignValue,
              milestotal,
              isTripEditable,
              rmethods,
            } = prevState;
            if (res.order_status && res.order_status.code === 'CMP') {
              isTripEditable = true;
            }
            milestotal = res.calc_miles;
            orderValue.id = res.id;
            orderValue.billed_to = res.billed_to.id;
            orderValue.billed_to_name = res.billed_to.name;
            orderValue.broker = res.broker;
            orderValue.csa_compliant = res.csa_compliant;
            orderValue.custom_surcharge = res.custom_surcharge;
            orderValue.surcharge = res.surcharge;
            orderValue.beforetax = res.sub_total;
            orderValue.custom_tax_total = res.custom_tax_total;
            orderValue.comm_type = res.comm_type && res.comm_type.id;
            orderValue.comm_info = res.comm_info;
            orderValue.equip_type = res.equip_type && res.equip_type.id;
            orderValue.equip_info = res.equip_info;
            orderValue.cstm_broker = res.cstm_broker;
            orderValue.broker_cnt_name = res.broker_cnt_name;
            orderValue.broker_cnt_email = res.broker_cnt_email;
            orderValue.broker_cnt_phone = res.broker_cnt_phone;
            orderValue.broker_cnt_fax = res.broker_cnt_fax;
            orderValue.dispatch_by = res.dispatch_by;
            orderValue.extra_cost = res.extra_cost;
            orderValue.extra_cost_desc = res.extra_cost_desc;
            orderValue.order_status = res.order_status && res.order_status.id;
            orderValue.frt_cost = res.frt_cost;
            orderValue.frt_cost_per_mile = res.frt_cost_per_mile;
            orderValue.files = res.files;
            orderValue.invoice = res.invoice;
            orderValue.urgent = res.urgent ? "Yes" : "No";
            orderValue.mat_typ_haz = res.mat_typ_haz;
            orderValue.mat_typ_ref = res.mat_typ_ref;
            const [day, month, ...rest] = res.order_date.split("-");
            const [year, time] = rest[0].split(" ")
            orderValue.order_date = new Date(`${year}-${month}-${day} ${time}`);

            orderValue.order_no = res.order_no;
            orderValue.order_type = res.order_type && res.order_type.id;
            if (res.rate_mthd.code === 'PERMILE') {
              rmethods = res.rate_mthd.code;
            }
            orderValue.rate_mthd = res.rate_mthd.id;
            orderValue.load_type = res.load_type;
            orderValue.ref_no = res.ref_no;
            orderValue.sales_by = res.sales_by;
            orderValue.order_cur = res.order_cur.key;
            orderValue.sales_note = res.sales_note;
            orderValue.sub_total = res.sub_total;
            orderValue.surcharge = res.surcharge;
            orderValue.surcharge_typ = res.surcharge_typ.id;
            orderValue.tarp_type = res.tarp_type;
            orderValue.tax_total = res.tax_total;
            orderValue.tax_type = res.tax_type.id;
            orderValue.total = res.total;
            orderValue.trip = res.trip;
            for (let i = 0; i < res.items.length; i++) {
              let xdate = getFormattedDate(
                res.items[i].req_date,
                'DD-MM-YYYY hh:mm:ss',
                false
              );
              consignValue.push({
                id: res.items[i].id,
                type: res.items[i].type,
                item_status: res.items[i].item_status?.id,
                location: res.items[i].location,
                postal_code: res.items[i].postal_code,
                province: res.items[i].province,
                city: res.items[i].city,
                loc_nm: res.items[i].loc_nm,
                req_date: new Date(xdate),
                pkg_type: res.items[i].pkg_type.id,
                qty: res.items[i].qty,
                length: res.items[i].length,
                weight: res.items[i].weight,
                unit: res.items[i].unit,
                desc: res.items[i].desc,
                note: res.items[i].note,
                info: res.items[i].info,
                apnt_type: res.items[i].apnt_type && res.items[i].apnt_type.id,
                status: true,
              });

              if (res.items[i].type === 'S') {
                qtyval.push(parseInt(res.items[i].qty));
                lenval.push(parseInt(res.items[i].length));
                weightval.push(parseInt(res.items[i].weight));
              }
              if (res.items[i].type === 'C') {
                qtyvalC.push(parseInt(res.items[i].qty));
              }
            }
            if (res.rate_mthd.code === 'PERMILE') {
              this.handleCallMiles();
            }
            this.getTaxBeforeTotal();
            this.setState({
              qtytotal: qtyval.reduce((a, b) => a + b, 0),
              qtytotalC: qtyvalC.reduce((a, b) => a + b, 0),
              lentotal: lenval.reduce((a, b) => a + b, 0),
              weighttotal: weightval.reduce((a, b) => a + b, 0),
            });
            loadingsShow('none');
            return {
              orderValue,
              consignValue,
              milestotal,
              isTripEditable,
              rmethods,
            };
          });
        }
      }
    }
  };

  getCustomerData = async () => {
    let ddcustomer = [], ddbrokder = [];

    let res = await getApiAsyn(
      `customers?paged=true&page=1&size=50000&fields=id,name,currency_id,broker,email,cont_name,phone`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      istSearchList = res && res.page && res.page.total_elements;
      if (istSearchList > 0) {
        ddbrokder = res._embedded.content.filter((x) => x.broker === true);

        ddcustomer = res._embedded.content;
        ddcustomer = ddcustomer.map((item) => ({
          name: item.name,
          value: item.id,
          currency_id: item.currency_id,
          broker: item.broker,
          email: item.email,
          cont_name: item.cont_name,
          phone: item.phone,
        }));

        ddbrokder = ddbrokder.map((item) => ({
          name: item.name,
          value: item.id,
          phone: item.phone,
          email: item.email,
          cont_name: item.cont_name,
        }));
        ddbrokder.unshift({
          name: 'select...',
          value: '-1',
        });
        this.setState({
          CustomerDropdonw: ddcustomer,
          BrokerDropdown: ddbrokder,
        });
      }
    }
  };

  getAPPNT_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=APPNT_TYPE`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      dddata = res;

      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));
      this.setState({
        APPNT_TYPEDropdown: dddata,
      });
    }
  };
  getEQUIP_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=EQUIP_TYPE`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      dddata = res;
      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      dddata.unshift({
        name: 'select',
        value: '-1',
      });
      this.setState({
        EQUIP_TYPEDropdown: dddata,
      });
    }
  };

  getCOMM_TYPEDropdonw = async () => {
    let dddata = [];
    let res = await getApiAsyn(
      `types?type=COMM_TYPE`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      dddata = res;
      dddata = dddata.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));
      dddata.unshift({
        name: 'select...',
        value: '-1',
      });
      let comm_type = dddata.filter((item) => item.code === 'DRY');
      if (comm_type.length > 0) {
        this.setState({
          orderValue: {
            ...this.state.orderValue,
            comm_type: comm_type[0].value,
          },
        });
      }
      this.setState({
        COMM_TYPEDropdown: dddata,
      });
    }
  };

  getCurrencyDropDown = async () => {
    let ddcurrencyDD = [];
    let res = await getApiAsyn(`currencies`, cookies.get('authorization'));

    if (res && res.length > 0 && res.status !== false) {
      ddcurrencyDD = res;
      ddcurrencyDD = ddcurrencyDD.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));

      this.setState({
        orderValue: {
          ...this.state.orderValue,
          order_cur: ddcurrencyDD[0].value,
        },
        CurDropdown: ddcurrencyDD,
      });
    }
  };

  getBrokderDispacherSales = () => {
    let dddispacher = [],
      ddsales = [],
      allData = [];
    getApi(
      `employees?paged=true&page=1&size=50000`,
      cookies.get('authorization')
    ).then((res) => {
      if (res && res.status !== false) {
        istSearchList = res && res.page && res.page.total_elements;
        if (istSearchList > 0) {
          allData = res._embedded.content;

          dddispacher = allData.map((item) => ({
            name: item.first,
            value: item.id,
            phone: item.phone,
            email: item.email,
          }));
          dddispacher.unshift({
            name: 'select...',
            value: '-1',
          });
          ddsales = allData.map((item) => ({
            name: item.first,
            value: item.id,
            phone: item.phone,
            email: item.email,
          }));

          ddsales.unshift({
            name: 'select...',
            value: '-1',
          });
          this.setState({
            DispatcherDropdown: dddispacher,
            SalesDropdown: ddsales,
          });
        }
      }
    });
  };
  handleDate = (date, name) => {
    const { orderValue } = this.state;
    orderValue[name] = date;
    this.setState({
      [orderValue]: orderValue,
      PromptPrevent: true,
    });
  };

  getCurrency = () => {
    let ddcurrency = [];
    getApi(`currencies`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddcurrency = res;
        ddcurrency = ddcurrency.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          currencyList: ddcurrency,
        });
      }
    });
  };

  getAssets = () => {
    let ddasset = [];
    getApi(`assets`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddasset = res._embedded.content;
        ddasset = ddasset.map((item) => ({
          name: item.code,
          value: item.id,
        }));
        this.setState({
          defaultAssetList: ddasset,
        });
      }
    });
  };

  getRevenue = () => {
    let ddrevenue = [];
    getApi(`types?type=REVENUE_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddrevenue = res;
          ddrevenue = ddrevenue.map((item) => ({
            name: item.value,
            value: item.key,
          }));
          this.setState({
            revenueList: ddrevenue,
          });
        }
      }
    );
  };
  getTax = () => {
    let ddtax = [];
    getApi(`types?type=TAX_TYPE`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddtax = res;
        ddtax = ddtax.map((item) => ({
          name: item.value,
          value: item.key,
          code: item.code,
        }));
        let selected = ddtax.filter((item) => item.code === 'CSTTAX');
        if (selected.length > 0) {
          this.setState({
            orderValue: {
              ...this.state.orderValue,
              tax_type: selected[0].value,
            },
          });
        }
        this.setState({
          taxList: ddtax,
        });
      }
    });
  };

  getOrderType = () => {
    let ddordertype = [];
    getApi(`types?type=ORDER_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddordertype = res;
          ddordertype = ddordertype.map((item) => ({
            name: item.value,
            value: item.key,
            code: item.code,
          }));
          let odtype = ddordertype.filter((item) => item.code === 'LCL');

          if (odtype.length > 0) {
            this.setState({
              orderValue: {
                ...this.state.orderValue,
                order_type: odtype[0].value,
              },
            });
          }
          this.setState({
            OrderDropdonw: ddordertype,
          });
        }
      }
    );
  };

  getOrderId = () => {
    getApi(`orders?intent=index`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        this.setState({
          orderValue: {
            ...this.state.orderValue,
            order_no: res.message,
          },
        });
      }
    });
  };

  getOrderStatusDropdown = async () => {
    let ddodrtype = [];
    let res = await getApiAsyn(
      `types?type=ORDER_STATUS`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      ddodrtype = res;
      ddodrtype = ddodrtype.map((item) => ({
        name: item.value,
        value: item.key,
        code: item.code,
      }));
      let selected = ddodrtype.filter((item) => item.code === 'NEW');
      if (selected.length > 0) {
        this.setState({
          orderValue: {
            ...this.state.orderValue,
            order_status: selected[0].value,
          },
        });
      }
      this.setState({
        OrderStatusDropdonw: ddodrtype,
      });
    }
  };

  getTrapType = () => {
    let ddtrapype = [];
    getApi(`types?type=TARP_TYPE`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddtrapype = res;
        ddtrapype = ddtrapype.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          TrapDropdonw: ddtrapype,
        });
      }
    });
  };

  getRateMethod = () => {
    let ddratemethod = [];
    getApi(`types?type=ORDER_RT_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          console.log(res);
          ddratemethod = res;
          ddratemethod = ddratemethod.map((item) => ({
            name: item.value,
            value: item.key,
            code: item.code,
          }));

          let selected = ddratemethod.filter((item) => item.code === 'FLAT');
          if (selected.length > 0) {
            this.setState({
              orderValue: {
                ...this.state.orderValue,
                rate_mthd: selected[0].value,
              },
            });
          }
          this.setState({
            ReatMethodDropdonw: ddratemethod,
          });
        }
      }
    );
  };

  getFuelSurcharge = () => {
    let ddfulesercharge = [];
    getApi(`types?type=FUEL_SRCH_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddfulesercharge = res;
          ddfulesercharge = ddfulesercharge.map((item) => ({
            name: item.value,
            value: item.key,
            code: item.code,
          }));

          let selected = ddfulesercharge.filter(
            (item) => item.code === 'ABSOLUTE'
          );

          if (selected.length > 0) {
            this.setState({
              orderValue: {
                ...this.state.orderValue,
                surcharge_typ: selected[0].value,
              },
            });
          }
          this.setState({
            FuelSurchageDropdonw: ddfulesercharge,
          });
        }
      }
    );
  };

  getOrderItemStatusDropdonw = () => {
    let ddpackgype = [];
    getApi(`types?type=ORDER_ITEM_STATUS`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddpackgype = res;
          ddpackgype = ddpackgype.map((item) => ({
            name: item.value,
            value: parseInt(item.key),
            code: item.code,
          }));
          this.setState({
            ORDER_ITEM_STATUSDropDonw: ddpackgype,
          });
        }
      }
    );
  };
  getPackgType = () => {
    let ddpackgype = [];
    getApi(`types?type=PACKAGE_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddpackgype = res;
          ddpackgype = ddpackgype.map((item) => ({
            name: item.value,
            code: item.code,
            value: parseInt(item.key),
          }));
          this.setState({
            PackgDropdonw: ddpackgype,
          });
        }
      }
    );
  };

  getUnitType = () => {
    let ddunitype = [];
    getApi(`types?type=PACKAGE_UNIT_TYPE`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddunitype = res;

          ddunitype = ddunitype.map((item) => ({
            name: item.value,
            code: item.code,
            value: item.key,
          }));
          this.setState({
            UnitDropdonw: ddunitype,
          });
        }
      }
    );
  };

  getCountry = () => {
    let ddCoounty = [];
    getApi(`countries`, cookies.get('authorization')).then((res) => {
      if (res && res.status !== false) {
        ddCoounty = res;
        ddCoounty = ddCoounty.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          countryList: ddCoounty,
        });
      }
    });
  };
  getState = (id) => {
    let ddState = [];
    getApi(`countries/${id}/states`, cookies.get('authorization')).then(
      (res) => {
        if (res && res.status !== false) {
          ddState = res;
          ddState = ddState.map((item) => ({
            name: item.value,
            value: item.key,
          }));
          this.setState({
            stateList: ddState,
          });
        }
      }
    );
  };

  getCity = (id, cid) => {
    let ddCity = [];
    getApi(
      `countries/${id}/states/${cid}/cities`,
      cookies.get('authorization')
    ).then((res) => {
      if (res && res.status !== false) {
        ddCity = res;
        ddCity = ddCity.map((item) => ({
          name: item.value,
          value: item.key,
        }));
        this.setState({
          cityList: ddCity,
        });
      }
    });
  };

  handleSeachableDropdonwConsign = (val, name, index) => {
    const { consignValue } = this.state;
    consignValue[index][name] = val;
    this.setState({
      [consignValue]: consignValue,
    });
  };

  getDispatchByCalc = (val) => {
    const { DispatcherDropdown } = this.state;
    let cp = DispatcherDropdown.filter((item) => {
      return item.value === val;
    });
    let dis = {};
    if (val !== '-1') {
      dis = {
        name: cp && cp[0].name,
        phone: cp && cp[0].phone,
        email: cp && cp[0].email,
      };
    } else {
      dis = {
        name: '',
        phone: '',
        email: '',
      };
    }
    this.setState({
      dispatch_byContact: dis,
    });
  };

  getBrokerByCalc = (val) => {
    let { BrokerDropdown } = this.state;
    let cp = BrokerDropdown.filter((x) => x.value === val);

    let dis = {};
    if (val !== '-1') {
      dis = {
        name: cp && cp[0].name,
        phone: cp && cp[0].phone,
        email: cp && cp[0].email,
      };
    } else {
      dis = {
        name: '',
        phone: '',
        email: '',
      };
    }
    this.setState((prevState) => {
      let { brokerContact } = prevState;
      brokerContact = dis;
      return { brokerContact };
    });
  };
  getSalesByCalc = (val) => {
    const { SalesDropdown } = this.state;
    let cp = SalesDropdown.filter((item) => {
      return item.value === val;
    });
    let dis = {};
    if (val !== '-1') {
      dis = {
        name: cp && cp[0].name,
        phone: cp && cp[0].phone,
        email: cp && cp[0].email,
      };
    } else {
      dis = {
        name: '',
        phone: '',
        email: '',
      };
    }
    this.setState({
      sales_byContact: dis,
    });
  };

  CalcCurrencies = (id) => {
    const { CustomerDropdonw } = this.state;
    let cid = CustomerDropdonw.filter((item) => {
      return item.value === id;
    });
    this.setState((prevState) => {
      let { orderValue, PromptPrevent } = prevState;
      orderValue.order_cur = cid[0].currency_id;
      PromptPrevent = true;
      return { orderValue, PromptPrevent };
    });
  };
  handleSeachableDropdonw = (val, name) => {
    const { orderValue, taxList, ReatMethodDropdonw } = this.state;
    orderValue[name] = val;
    this.setState({
      [orderValue]: orderValue,
      PromptPrevent: true,
    });

    if (name === 'country_id') {
      this.getState(val);
      this.setState({
        cntryId: val,
      });
    }
    if (name === 'state_id') {
      const { cntryId } = this.state;
      this.getCity(cntryId, val);
    }
    if (name === 'rate_mthd') {
      let rmethod = ReatMethodDropdonw.filter((item) => item.value === val);
      if (rmethod.length > 0) {
        if (name === 'rate_mthd' && rmethod[0].code === 'PERMILE') {
          this.handleCallMiles();
          this.setState({
            rmethods: rmethod[0].code,
          });
        } else {
          this.setState({
            rmethods: '',
          });
        }
      } else {
        this.setState((prevState) => {
          let { orderValue } = prevState;
          orderValue.frt_cost_per_mile = 0;
          return { orderValue };
        });
      }
    }

    if (name === 'dispatch_by') {
      if (val === '-1') {
        this.setState({
          orderValue: {
            ...orderValue,
            dispatch_by: undefined,
          },
        });
      }
      this.getDispatchByCalc(val);
    }
    if (name === 'equip_type') {
      if (val === '-1') {
        this.setState({
          orderValue: {
            ...orderValue,
            equip_type: undefined,
          },
        });
      }
    }
    if (name === 'comm_type') {
      if (val === '-1') {
        this.setState({
          orderValue: {
            ...orderValue,
            comm_type: undefined,
          },
        });
      }
    }

    if (name === 'broker') {
      if (val === '-1') {
        this.setState({
          orderValue: {
            ...orderValue,
            broker: undefined,
          },
        });
      }
      this.getBrokerByCalc(val);
    }
    if (name === 'sales_by') {
      if (val === '-1') {
        this.setState({
          orderValue: {
            ...orderValue,
            sales_by: undefined,
          },
        });
      }
      this.getSalesByCalc(val);
    }
    if (name === 'tax_type') {
      for (let i = 0; i < taxList.length; i++) {
        if (taxList[i].value === val) {
          this.setState({
            tax_label: taxList[i].name,
          });
        }
      }
    }

    this.getCalculateTotal();
    this.getTaxBeforeTotal();
  };

  getCalculateTotal = () => {
    const { taxList, orderValue } = this.state;
    for (let i = 0; i < taxList.length; i++) {
      if (taxList[i].value === orderValue.tax_type) {
        this.setState({
          tax_label: taxList[i].name,
        });
      }
    }
    this.setState((prevState) => {
      const { tax_label, orderValue } = prevState;

      if (
        !tax_label.includes('CustomTax') &&
        (tax_label !== undefined || tax_label !== '')
      ) {
        let taxPercent = tax_label.substring(tax_label.lastIndexOf('-') + 1);

        taxPercent = taxPercent.slice(0, -1);
        let taxtotal = (parseInt(orderValue.beforetax) * taxPercent) / 100;
        orderValue.tax_total = taxtotal;
      } else {
        let custom_tax_total =
          isNaN(orderValue.custom_tax_total) ||
            orderValue.custom_tax_total === undefined ||
            orderValue.custom_tax_total == null ||
            orderValue.custom_tax_total === ''
            ? 0
            : orderValue.custom_tax_total;

        let taxtotal =
          (parseInt(orderValue.beforetax) * custom_tax_total) / 100;
        orderValue.tax_total = taxtotal;
      }
      return { orderValue };
    });
    this.getTaxBeforeTotal();
  };

  getTaxBeforeTotal = () => {
    this.setState((prevState) => {
      const { orderValue, FuelSurchageDropdonw } = prevState;
      let stype = FuelSurchageDropdonw.filter(
        (item) => item.code === 'ABSOLUTE'
      );

      if (orderValue.surcharge_typ === stype[0].value) {
        let frt_cost =
          isNaN(orderValue.frt_cost) ||
            orderValue.frt_cost === undefined ||
            orderValue.frt_cost == null ||
            orderValue.frt_cost === ''
            ? 0
            : orderValue.frt_cost;
        let frtCost = parseFloat(frt_cost);

        let custom_surcharge =
          isNaN(orderValue.custom_surcharge) ||
            orderValue.custom_surcharge === undefined ||
            orderValue.custom_surcharge == null ||
            orderValue.custom_surcharge === ''
            ? 0
            : orderValue.custom_surcharge;
        let customsurcharge = parseFloat(custom_surcharge);

        let extra_cost =
          isNaN(orderValue.extra_cost) ||
            orderValue.extra_cost === undefined ||
            orderValue.extra_cost == null ||
            orderValue.extra_cost === ''
            ? 0
            : orderValue.extra_cost;
        let extraconst = parseFloat(extra_cost);

        orderValue.beforetax = (frtCost + customsurcharge + extraconst).toFixed(
          2
        );

        let beforetaxs =
          isNaN(orderValue.beforetax) ||
            orderValue.beforetax === undefined ||
            orderValue.beforetax == null ||
            orderValue.beforetax === ''
            ? 0
            : orderValue.beforetax;
        let beforetax = parseFloat(beforetaxs);
        let tax_total =
          isNaN(orderValue.tax_total) ||
            orderValue.tax_total === undefined ||
            orderValue.tax_total == null ||
            orderValue.tax_total === ''
            ? 0
            : orderValue.tax_total;
        let totalTax = parseFloat(tax_total);
        orderValue.surcharge = parseFloat(customsurcharge).toFixed(2);
        orderValue.total = parseFloat(beforetax + totalTax).toFixed(2);
      } else {
        let frt_cost =
          isNaN(orderValue.frt_cost) ||
            orderValue.frt_cost === undefined ||
            orderValue.frt_cost == null ||
            orderValue.frt_cost === ''
            ? 0
            : orderValue.frt_cost;
        let frtCost = parseFloat(frt_cost);

        let custom_surcharge =
          isNaN(orderValue.custom_surcharge) ||
            orderValue.custom_surcharge === undefined ||
            orderValue.custom_surcharge == null ||
            orderValue.custom_surcharge === ''
            ? 0
            : orderValue.custom_surcharge;
        let customsurcharge = parseFloat(custom_surcharge);

        let extra_cost =
          isNaN(orderValue.extra_cost) ||
            orderValue.extra_cost === undefined ||
            orderValue.extra_cost == null ||
            orderValue.extra_cost === ''
            ? 0
            : orderValue.extra_cost;
        let extraconst = parseFloat(extra_cost);
        let percentSurTye = (frtCost * customsurcharge) / 100;
        orderValue.surcharge = percentSurTye.toFixed(2);
        orderValue.beforetax = (frtCost + percentSurTye + extraconst).toFixed(
          2
        );

        let beforetaxs =
          isNaN(orderValue.beforetax) ||
            orderValue.beforetax === undefined ||
            orderValue.beforetax == null ||
            orderValue.beforetax === ''
            ? 0
            : orderValue.beforetax;
        let beforetax = parseFloat(beforetaxs);
        let tax_total =
          isNaN(orderValue.tax_total) ||
            orderValue.tax_total === undefined ||
            orderValue.tax_total == null ||
            orderValue.tax_total === ''
            ? 0
            : orderValue.tax_total;
        let totalTax = parseFloat(tax_total);
        orderValue.total = parseFloat(beforetax + totalTax).toFixed(2);
      }
      return { orderValue };
    });
  };

  handleChangeGen = (event) => {
    this.getTaxBeforeTotal();
    const { orderValue, milestotal } = this.state;
    orderValue[event.target.name] = event.target.value;
    if (event.target.name === 'billed_to_name' && event.target.value === '') {
      orderValue['billed_to_name'] = '';
      orderValue['billed_to'] = '';
    }
    if (event.target.name === 'billed_to_name') {
      this.getAutosuggest(event.target.value);
    }
    if (event.target.name === 'urgent') {
      orderValue['urgent'] = event.target.value === 'Yes'
    }
    this.setState({
      [orderValue]: orderValue,
      PromptPrevent: true,
    });

    if (
      event.target.name === 'frt_cost_per_mile' ||
      orderValue.rate_mthd === 222
    ) {
      if (milestotal === undefined || isNaN(milestotal)) {
        this.handleCallMiles();
      }

      this.setState((prevState) => {
        const { orderValue } = prevState;
        orderValue.frt_cost = milestotal * orderValue.frt_cost_per_mile;
        return { orderValue, milestotal };
      });
    }
    if (event.target.name === 'custom_tax_total') {
      this.setState(
        (prevState) => {
          let { orderValue } = prevState;
          let customTax =
            orderValue.custom_tax_total !== ''
              ? orderValue.custom_tax_total
              : 0;
          let taxtotal =
            (parseFloat(orderValue.beforetax) * parseFloat(customTax)) / 100;
          orderValue.tax_total = taxtotal;
          return { orderValue };
        },
        () => {
          this.getTaxBeforeTotal();
          this.getCalculateTotal();
        }
      );
      this.getTaxBeforeTotal();
    }

    this.getTaxBeforeTotal();
    this.getCalculateTotal();
  };

  handleChangeGenFile = (event) => {
    const { orderValue } = this.state;
    if (event.target.value !== '') {
      orderValue[event.target.name] = event.target.value;
      let fname = event.target.files[0]?.name;
      fname = fname.substr(0, fname.lastIndexOf("."));
      if (event.target.type === 'file' && fname.length > 2) {
        orderValue[event.target.name] = event.target.files[0];
        FileData.push(event.target.files[0]);
        this.setState({
          [orderValue]: orderValue,
          PromptPrevent: true,
          ImageDisplay: FileData,
        });
      }
      else {
        this.handelMessage('File name must have atlest 3 charector which you are trying to upload', 'errorBoll');
      }
    }
  };

  handleRemoveFile = (item, i) => {
    let { orderValue, ImageDisplay } = this.state;
    let data = ImageDisplay;
    let newData = Object.values(data).filter((user, idx) => idx !== i);
    FileData.splice(i, 1);
    this.setState({
      ImageDisplay: newData,
      PromptPrevent: true,
      orderValue: {
        ...orderValue,
        files: newData,
      },
    });
  };
  updateFrtCost = () => {
    this.getTaxBeforeTotal();
    this.setState((prevState) => {
      const { orderValue, milestotal } = prevState;
      let frtpm = isNaN(orderValue.frt_cost_per_mile)
        ? 0
        : orderValue.frt_cost_per_mile;
      if (orderValue.rate_mthd !== 223) {
        orderValue.frt_cost = milestotal * frtpm;
      }

      return { orderValue, milestotal };
    });
  };
  handleChangeGenCheckedCB = (event) => {
    const { orderValue } = this.state;
    orderValue[event.target.name] = event.target.checked;
    this.setState({
      [orderValue]: orderValue,
      PromptPrevent: true,
    });
  };
  handleSeachableconsignDropdonw = (val, name) => {
    const { consignValue } = this.state;

    consignValue[name] = val;

    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleChange = (e, index) => {
    let qtyval = [],
      lenval = [],
      qtyvalC = [],
      weightval = [];
    let { consignValue } = this.state;
    const { name, value } = e.target;

    consignValue = [...consignValue];
    consignValue[index][name] = value;
    if (
      name === 'qty' ||
      name === 'length' ||
      name === 'weight' ||
      name === 'pkg_type' ||
      name === 'unit'
    ) {
      consignValue[index][name] = parseInt(value);
    }
    this.setState({ consignValue, PromptPrevent: true });

    for (let i = 0; i < consignValue.length; i++) {
      if (consignValue[i].type === 'S') {
        qtyval.push(parseInt(consignValue[i].qty));
        lenval.push(parseInt(consignValue[i].length));
        weightval.push(parseInt(consignValue[i].weight));
      }
      if (consignValue[i].type === 'C') {
        qtyvalC.push(parseInt(consignValue[i].qty));
      }
    }

    this.setState({
      qtytotal: qtyval.reduce((a, b) => a + b, 0),
      qtytotalC: qtyvalC.reduce((a, b) => a + b, 0),
      lentotal: lenval.reduce((a, b) => a + b, 0),
      weighttotal: weightval.reduce((a, b) => a + b, 0),
    });
  };

  handleCallMiles = async () => {
    const { consignValue } = this.state;
    if (consignValue.length === 0) {
      this.handelMessage('Please add order from list', 'errorBoll');
    } else {
      let zipData = '';
      for (let i = 0; i < consignValue.length; i++) {
        let postalCode = consignValue[i].postal_code;
        zipData += postalCode + '|';
      }
      let res = await getApiAsyn(
        `miles?value=${zipData}`,
        cookies.get('authorization')
      );
      if (res.message) {
        let mile = parseInt(res.message);
        this.setState({
          milestotal: Math.round(mile),
        });
      }
      this.updateFrtCost();
      this.getTaxBeforeTotal();
      this.getCalculateTotal();
    }
  };

  /* handleCallMiles = () => {
    let zipData = '';
    const { consignValue } = this.state;

    for (let i = 0; i < consignValue.length; i++) {
      zipData += consignValue[i].postal_code + '|';
    }

    getApi(`miles?value=${zipData}`, cookies.get('authorization')).then(
      (res) => {
        console.log('Miles', res);
        let mile = parseInt(res.message);
        this.setState({
          milestotal: Math.round(mile),
        });
        this.updateFrtCost();
        this.getTaxBeforeTotal();
        this.getCalculateTotal();
      }
    );
  };
*/
  handleDateConsig = (date, name, index) => {
    const { consignValue } = this.state;
    consignValue[index][name] = date;
    this.setState({
      [consignValue]: consignValue,
      PromptPrevent: true,
    });
  };

  handleChangeGenChecked = (event) => {
    const { orderValue } = this.state;
    orderValue[event.target.name] = event.currentTarget.value;
    this.setState({
      [orderValue]: orderValue,
      PromptPrevent: true,
    });
  };

  handleValidation = () => {
    const { orderValue, isConfirmation } = this.state;

    let errors = {};
    let formIsValid = true;
    //Name
    if (isNotify === false && isConfirmation === false) {
      this.getNotifiyShipperConsignee();
    }
    if (!orderValue['billed_to']) {
      formIsValid = false;

      errors['billed_to'] = 'This is a mandatory field.';
    }
    if (!orderValue['order_cur']) {
      formIsValid = false;

      errors['order_cur'] = 'This is a mandatory field.';
    }
    if (!orderValue['order_type']) {
      formIsValid = false;

      errors['order_type'] = 'This is a mandatory field.';
    }
    if (!orderValue['load_type']) {
      formIsValid = false;

      errors['load_type'] = 'This is a mandatory field.';
    }

    if (!orderValue['order_date']) {
      formIsValid = false;
      errors['order_date'] = 'This is a mandatory field.';
    }

    if (!orderValue['tax_type']) {
      formIsValid = false;
      errors['tax_type'] = 'This is a mandatory field.';
    }
    if (!orderValue['surcharge_typ']) {
      formIsValid = false;
      errors['surcharge_typ'] = 'This is a mandatory field.';
    }
    if (!orderValue['rate_mthd']) {
      formIsValid = false;
      errors['rate_mthd'] = 'This is a mandatory field.';
    }
    if (orderValue['surcharge_typ'] === 225 && !orderValue['surcharge']) {
      formIsValid = false;
      errors['surcharge'] = 'This is a mandatory field.';
    }

    if (orderValue['tax_total'] < 0) {
      formIsValid = false;
      errors['tax_total'] = 'This is a mandatory field.';
    }
    if (
      orderValue['sales_note'] !== undefined &&
      orderValue['sales_note'] !== '' &&
      orderValue['sales_note'] != null &&
      orderValue['sales_note'].length > 500
    ) {
      formIsValid = false;
      errors['sales_note'] = 'Sales notes can not be more the 500 charector.';
    }
    if (
      orderValue['ref_no'] !== undefined &&
      orderValue['ref_no'] !== '' &&
      orderValue['ref_no'] != null &&
      orderValue['ref_no'].length > 20
    ) {
      formIsValid = false;
      errors['ref_no'] = 'Ref no can not be more the 20 charector.';
    }
    if (
      orderValue['equip_info'] !== undefined &&
      orderValue['equip_info'] !== '' &&
      orderValue['equip_info'] != null &&
      orderValue['equip_info'].length > 200
    ) {
      formIsValid = false;
      errors['equip_info'] =
        'Equipment type info can not be more the 200 charector.';
    }
    if (
      orderValue['comm_info'] !== undefined &&
      orderValue['comm_info'] !== '' &&
      orderValue['comm_info'] != null &&
      orderValue['comm_info'].length > 200
    ) {
      formIsValid = false;
      errors['comm_info'] =
        'Commodity type info can not be more the 200 charector.';
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };

  handleModalNotifiyCSIsEqual = () => {
    this.setState((prevState) => {
      let { CSModal } = prevState;
      CSModal = prevState.CSModal === false ? true : false;
      return { CSModal };
    });
  };
  handleModalRefNo = () => {
    this.setState((prevState) => {
      let { showRefConfirmation } = prevState;
      showRefConfirmation = prevState.showRefConfirmation === false ? true : false;
      return { showRefConfirmation };
    });
  };

  getNotifiyCSConfirm = () => {
    this.setState((prevState) => {
      let { CSModal, CSConfirm } = prevState;
      CSConfirm = true;

      CSModal = false;
      return { CSModal, CSConfirm };
    });
  };
  getRefConfirm = () => {
    this.setState((prevState) => {
      let { showRefConfirmation, ConfrimRefConfirmation } = prevState;
      ConfrimRefConfirmation = true;

      showRefConfirmation = false;
      return { showRefConfirmation, ConfrimRefConfirmation };
    });
  };
  getNotifiyCSIsEqual = () => {
    let formIsValid = true;
    const { consignValue, CSConfirm } = this.state;
    let C = consignValue.filter((item) => item.type === 'C');
    let S = consignValue.filter((item) => item.type === 'S');
    if (C.length > S.length && CSConfirm === false) {
      formIsValid = false;
      this.setState((prevState) => {
        let { CSModal, CSMessage } = prevState;
        CSMessage = (
          <span>
            Consignee total is greater than Shipper total. Are you sure, you
            want to continue?
          </span>
        );
        CSModal = true;

        return { CSModal, CSMessage };
      });
    }
    if (S.length > C.length && CSConfirm === false) {
      formIsValid = false;
      this.setState((prevState) => {
        let { CSModal, CSMessage } = prevState;
        CSMessage = (
          <span>
            Shipper total is greater then Consignee total, Are sure you want to
            continue
          </span>
        );
        CSModal = true;

        return { CSModal, CSMessage };
      });
    }
    return formIsValid;
  };

  handleConfirmationClick = () => {
    this.setState({
      isConfirmation: true,
      showConfirmation: false,
    });
  };
  toggleConfirmationModal = () => {
    this.setState((prevState) => {
      let { showConfirmation } = prevState;
      showConfirmation = prevState.showConfirmation === false ? true : false;

      return { showConfirmation };
    });
  };

  getNotifiyShipperConsignee = () => {
    let countryToShip = [];
    let formIsValid = true;
    isNotify = false;
    const { consignValue, orderValue, isConfirmation } = this.state;
    for (let i = 0; i < consignValue.length; i++) {
      countryToShip.push(
        consignValue[i].location.substring(
          consignValue[i].location.lastIndexOf(',') + 1
        )
      );
    }
    if (orderValue.order_type === 214) {
      for (let t = 0; t < countryToShip.length; t++) {
        if (
          countryToShip[t + 1] !== undefined &&
          countryToShip[t + 1] !== countryToShip[t]
        ) {
          isNotify = true;
        }
      }
      if (isNotify === true && isConfirmation === false) {
        if (isConfirmation === false) {
          formIsValid = false;
        }

        this.handleCnfMeesage(
          'You have selected order type local but item you have added in order for different country. Are sure you want to continue',
          'errorBoll'
        );
      }
    }
    if (orderValue.order_type === 215) {
      for (let t = 0; t < countryToShip.length; t++) {
        if (
          countryToShip[t + 1] !== undefined &&
          countryToShip[t + 1] === countryToShip[t]
        ) {
          isNotify = true;
        }
        if (isNotify === true && isConfirmation === false) {
          if (isConfirmation === false) {
            formIsValid = false;
          }
          this.handleCnfMeesage(
            'Order type selected is Cross Border but line items added are in same country. Are you sure, you want to continue?',
            'errorBoll'
          );
        }
      }
    }

    return formIsValid;
  };
  handleRefCnfMeesage = (msg, boll, data) => {
    this.setState((prevState) => {
      let { showRefConfirmation, confirmationRfMessage, oderlist } = prevState;
      showRefConfirmation = true;
      confirmationRfMessage = msg;
      oderlist = data;
      return { showRefConfirmation, confirmationRfMessage, oderlist };
    });
  };
  handleCnfMeesage = (msg, boll, data) => {
    this.setState((prevState) => {
      let { showConfirmation, confirmationMessage, oderlist } = prevState;
      showConfirmation = true;
      confirmationMessage = msg;
      oderlist = data;
      return { showConfirmation, confirmationMessage, oderlist };
    });
  };
  handleSave = () => {
    if (
      this.handleValidation() &&
      this.getNotifiyShipperConsignee() &&
      this.getNotifiyCSIsEqual()
    ) {
      const {
        orderValue,
        consignValue,
        milestotal,
        qtytotalC,
        qtytotal,
        ConfrimRefConfirmation
      } = this.state;
      let consObject = [];
      for (let i = 0; i < consignValue.length; i++) {
        if (
          consignValue[i].location === '' ||
          consignValue[i].location === undefined
        ) {
          this.handelMessage('Location is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].province === '' ||
          consignValue[i].province === undefined
        ) {
          this.handleMessageData('Province is required', 'errorBolls');
          return false;
        }
        if (consignValue[i].city === '' || consignValue[i].city === undefined) {
          this.handleMessageData('City is required', 'errorBolls');
          return false;
        }
        if (
          consignValue[i].postal_code === '' ||
          consignValue[i].postal_code === undefined
        ) {
          this.handelMessage('Postal code is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].loc_nm === '' ||
          consignValue[i].loc_nm === undefined
        ) {
          this.handelMessage('Location Name is required', 'errorBoll');
          return false;
        }
        if (consignValue[i].qty === '' || consignValue[i].qty === undefined) {
          this.handelMessage('Qty is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].pkg_type === '' ||
          consignValue[i].pkg_type === undefined
        ) {
          this.handelMessage('Package type is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].item_status === '' ||
          consignValue[i].item_status === undefined
        ) {
          this.handelMessage('Item status is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].req_date === '' ||
          consignValue[i].req_date === undefined
        ) {
          this.handelMessage('Request date is required', 'errorBoll');
          return false;
        }
        if (
          consignValue[i].apnt_type === '' ||
          consignValue[i].apnt_type == null
        ) {
          this.handelMessage('Appointment type is required', 'errorBoll');
          return false;
        } else {
          consObject.push({
            id: consignValue[i].id || 0,
            type: consignValue[i].type,
            location: consignValue[i].location,
            postal_code: consignValue[i].postal_code,
            province: consignValue[i].province,
            city: consignValue[i].city,
            loc_nm:
              consignValue[i].loc_nm == null ||
                consignValue[i].loc_nm === undefined
                ? ''
                : consignValue[i].loc_nm,
            req_date: formatDate(consignValue[i].req_date),
            req_time: formatDate(consignValue[i].req_date),
            pkg_type: parseInt(consignValue[i].pkg_type) || null,
            qty: consignValue[i].qty,
            length: consignValue[i].length,
            weight: consignValue[i].weight,
            unit: consignValue[i].unit || null,
            desc: consignValue[i].desc,
            note: consignValue[i].note,
            info: consignValue[i].info,
            apnt_type: parseInt(consignValue[i].apnt_type),
            status: consignValue[i].status,
          });
        }
      }
      if (consignValue.length === 0) {
        this.handelMessage(
          'Can not create order without shipper or consignment items',
          'errorBoll'
        );
        return;
      }

      if (ConfrimRefConfirmation === false && (orderValue.ref_no !== "" && orderValue.ref_no !== undefined)) {
        this.handleSearchRef(orderValue.ref_no);
        return false;
      }
      const urgent = orderValue.urgent === "Yes" || orderValue.urgent === true ? true : false;
      let reqData = {
        id: orderValue.id || 0,
        calc_miles: milestotal,
        order_no: orderValue.order_no,
        billed_to: orderValue.billed_to,
        load_type: orderValue.load_type,
        tarp_type: orderValue.tarp_type,
        mat_typ_haz: orderValue.mat_typ_haz,
        mat_typ_ref: orderValue.mat_typ_ref,
        csa_compliant:
          orderValue.csa_compliant !== undefined &&
            orderValue.csa_compliant === 'true'
            ? true
            : false,
        order_date: getFormattedDate(orderValue.order_date, 'DD-MM-yyyy HH:mm:ss', true),
        broker: orderValue.broker,
        comm_type: orderValue.comm_type,
        comm_info: orderValue.comm_info,
        equip_type: orderValue.equip_type,
        equip_info: orderValue.equip_info,
        cstm_broker: orderValue.cstm_broker,
        broker_cnt_name: orderValue.broker_cnt_name,
        broker_cnt_email: orderValue.broker_cnt_email,
        broker_cnt_phone: orderValue.broker_cnt_phone,
        broker_cnt_fax: orderValue.broker_cnt_fax,
        urgent,
        sales_by: orderValue.sales_by,
        order_cur: orderValue.order_cur,
        sales_note: orderValue.sales_note,
        dispatch_by: orderValue.dispatch_by,
        order_type: orderValue.order_type,
        ref_no: orderValue.ref_no !== undefined ? orderValue.ref_no : '',
        rate_mthd: orderValue.rate_mthd,
        frt_cost_per_mile: parseFloat(orderValue.frt_cost_per_mile),
        frt_cost: parseFloat(orderValue.frt_cost),
        surcharge_typ: parseInt(orderValue.surcharge_typ),
        custom_surcharge: parseFloat(orderValue.custom_surcharge),
        surcharge: parseFloat(orderValue.surcharge),
        extra_cost: parseFloat(orderValue.extra_cost),
        extra_cost_desc: orderValue.extra_cost_desc,
        sub_total: parseFloat(orderValue.beforetax),
        tax_type: orderValue.tax_type,
        custom_tax_total:
          orderValue.custom_tax_total === undefined
            ? 0
            : parseFloat(orderValue.custom_tax_total),
        tax_total: parseFloat(orderValue.tax_total),
        total: parseFloat(orderValue.total),
        invoice: null,
        order_status: orderValue.order_status,
        items: consObject,
      };
      if (qtytotalC > qtytotal) {
        this.handelMessage(
          'Consignee total is greater than Shipper total',
          'notifyBoll'
        );
      }
      if (qtytotalC < qtytotal) {
        this.handelMessage(
          'Shipper total is greater than Consignee total',
          'notifyBoll'
        );
      }
      const formPayload = new FormData();
      const { ImageAddedS3 } = this.state;
      let files = Array.isArray(orderValue.files) && orderValue.files.map(file => {
        let updatedFile = file;
        for (let i = 0; i < ImageAddedS3.length; i++) {
          if (file.id === ImageAddedS3[i].id) {
            updatedFile = ImageAddedS3[i];
            updatedFile.s3Url = updatedFile.url;
            delete updatedFile.url;
          }
        }
        return updatedFile;
      })
      let DeletedData = ImageAddedS3.filter(item => item.status === "N");
      if (FileData !== undefined && FileData.length > 5) {
        this.handelMessage('You can attach max 5 file in a order', 'errorBoll');
        return false;
      }
      if (FileData !== undefined && FileData.length > 0 && FileData.length < 5) {
        for (let i = 0; i < FileData.length; i++) {
          formPayload.append(`file`, FileData[i]);
        }
      }
      if (DeletedData?.length > 0) {
        DeletedData = DeletedData.map(item => ({
          id: item.id,
          name: item.name,
          status: item.status,
          s3Url: item.url
        }))
        reqData = { ...reqData, files: DeletedData };
      }
      if (files?.length) {
        reqData = { ...reqData, files };
      }
      const json = JSON.stringify(reqData);
      const order = new Blob([json], {
        type: 'application/json',
      });
      formPayload.append('data', order);

      loadierShow('block');
      this.fileUpload(formPayload).then((response) => {
        FileData = [];
        loadierShow('none');
        this.setState(
          {
            legalBoll: true,
            PromptPrevent: false,
            successMessage: 'Order added successfully!',
          },
          function () {
            setTimeout(
              function () {
                this.setState({
                  legalBoll: false,
                  PromptPrevent: false,
                });
                window.location.replace('#/fleet/orders');
              }.bind(this),
              1000
            );
          }
        );
      });
    }
  };
  fileUpload = (reqData) => {
    if (this.props.match.params.id) {
      const url = baseUrls + `orders/${this.props.match.params.id}`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return put(url, reqData, config);
    } else {
      const url = baseUrls + 'orders';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return post(url, reqData, config);
    }
  };

  getImageDownload = async (url, name) => {
    var link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  };
  getFileData = async (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let res = await getApiAsynBlobImage(
          data[i].url,
          cookies.get('authorization')
        );
        this.setState((prevState) => {
          let { ImageAddedS3 } = prevState;
          ImageAddedS3.push({
            url: res.message,
            id: data[i].id,
            name: data[i].name,
            status: data[i].status,
          });
          return { ImageAddedS3 };
        });
      }
    }
  };
  handleSearchRef = async (ref_no) => {

    let res = await getApiAsyn(
      `orders?intent=search&paged=false&refno=${ref_no}`,
      cookies.get('authorization')
    );
    if (res?._embedded?.content.length > 0) {
      this.handleRefCnfMeesage(
        `Order Ref No Exists with mention order ids. Are you sure, you want to continue?`,
        'errorBoll',
        res?._embedded?.content
      );
    }
    else {
      this.setState((prevState) => {
        let { ConfrimRefConfirmation } = prevState;
        ConfrimRefConfirmation = true;
        return { ConfrimRefConfirmation };
      }, () => {
        this.handleSave()
      });
    }
  }

  handleS3Remove = (index) => {
    let { ImageAddedS3 } = this.state;
    ImageAddedS3[index].status = 'N';
    this.setState({ ImageAddedS3 });
  };
  getCustomerByID = () => {
    if (this.props.match !== undefined && this.props.match !== '') {
      if (
        this.props.match.params.id !== '' &&
        this.props.match.params.id !== undefined
      ) {
        getApi(
          `customers/${this.props.match.params.id}`,
          cookies.get('authorization')
        ).then((res) => {
          if (res && res.status !== false) {
            this.setState({
              orderValue: {
                asset_id: res.asset_id,
                cust_type: res.cust_type === 'true' ? true : false,
                revenue_id: res.revenue_id,
                tax_id: res.tax_id,
                due_days: res.due_days,

                name: res.name,
                cont_name: res.cont_name,
                email: res.email,
                phone: res.phone,
                phone2: res.phone2,
                site_url: res.site_url,
                address1: res.address1,
                address2: res.address2,
                country_id: res.country_id,
                state_id: res.state_id,
                city_id: res.city_id,
                currency_id: res.currency_id,
                postal_code: res.postal_code,
                tax_no: res.tax_no,
                crm_id: res.crm_id,
                info: res.info,
                eob_id: res.eob_id,
                invoice_by: res.invoice_by,
                service_pln_type: res.service_pln_type,
                broker: res.broker === 'true' ? true : false,
                broker_id: res.broker_id,
                due_amt: res.due_amt,
                order_cnt: res.order_cnt,
                invoice_cnt: res.invoice_cnt,
              },
            });
          }
        });
      }
    }
  };

  formatDates = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };
  handleAddClick = (id) => {
    const {
      PackgDropdonw,
      UnitDropdonw,
      APPNT_TYPEDropdown,
      ORDER_ITEM_STATUSDropDonw,
    } = this.state;
    let pkgType = PackgDropdonw.filter((item) => item.code === 'PKGBOXES');
    let unit = UnitDropdonw.filter((item) => item.code === 'POUNDS');
    let apt = APPNT_TYPEDropdown.filter((item) => item.code === 'NO');
    let item_status = ORDER_ITEM_STATUSDropDonw.filter(
      (item) => item.code === 'NEW'
    );
    this.setState((prevState) => ({
      consignValue: [
        ...prevState.consignValue,
        {
          id: 0,
          type: id,
          location: '',
          postal_code: '',
          city: '',
          province: '',
          req_date: new Date(),
          pkg_type: pkgType[0].value,
          qty: 0,
          length: 0,
          weight: 0,
          unit: unit[0].value,
          item_status: item_status[0].value,
          desc: '',
          note: '',
          info: '',
          apnt_type: apt[0].value,
          status: true,
        },
      ],
    }));
  };
  componentWillUnmount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  async componentDidMount() {
    await loadingsShow('block');
    FileData = [];
    if (
      this.props.match.params.id === '' ||
      this.props.match.params.id === undefined
    ) {
      this.getOrderId();
    }
    document.addEventListener('click', this.handleClickOutside, true);
    await this.getCurrencyDropDown();
    await this.getOrderItemStatusDropdonw();
    await this.getTax();
    await this.getRateMethod();
    await this.getAPPNT_TYPEDropdonw();
    await this.getEQUIP_TYPEDropdonw();
    await this.getCOMM_TYPEDropdonw();
    await this.getFuelSurcharge();
    await this.getUnitType();
    await this.getPackgType();
    await this.getOrderType();
    await this.getTrapType();
    await this.getCustomerData();
    await this.getBrokderDispacherSales();
    await this.getOrderStatusDropdown();
    await this.getDataByOrderID();
    await loadingsShow('none');
  }

  onDragEnd = (result) => {
    const { consignValue } = this.state;
    if (!result.destination) {
      return;
    }

    const updatedItems = this.reorder(
      consignValue,
      result.source.index,
      result.destination.index
    );

    this.setState({
      consignValue: updatedItems,
    });
  };

  handleRemoveClick = (index) => {
    let { consignValue, deleteItemIndex, orderItem } = this.state;
    if (orderItem === 0) {
      consignValue.splice(deleteItemIndex, 1);
      this.setState({
        consignValue,
      });
    } else {
      let updateObject = [];
      consignValue = JSON.parse(JSON.stringify(consignValue));
      consignValue[deleteItemIndex].status = false;

      for (let i = 0; i < consignValue.length; i++) {
        let reqdate = getFormattedDate(
          consignValue[i].req_date,
          'YYYY-MM-DD hh:mm:ss',
          false
        );

        updateObject.push({
          stop: i + 1,
          id: consignValue[i].id || 0,
          type: consignValue[i].type,
          loc_nm: consignValue[i].loc_nm,
          location: consignValue[i].location,
          postal_code: consignValue[i].postal_code,
          city: consignValue[i].city,
          province: consignValue[i].province,
          req_date: new Date(reqdate),
          req_time: new Date(reqdate),
          pkg_type: consignValue[i].pkg_type || null,
          qty: consignValue[i].qty,
          apnt_type: consignValue[i].apnt_type,
          length: consignValue[i].length,
          weight: consignValue[i].weight,
          unit: consignValue[i].unit || null,
          desc: consignValue[i].desc,
          note: consignValue[i].note,
          status: consignValue[i].status,
          item_status: consignValue[i].item_status,
        });
      }
      this.setState({
        consignValue: updateObject,
      });
    }
    this.toggleDeleteModal();
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    const {
      APPNT_TYPEDropdown,
      showRefConfirmation,
      EQUIP_TYPEDropdown,
      COMM_TYPEDropdown,
      ImageAddedS3,
      OrderStatusDropdonw,
      taxList,
      milestotal,
      CurDropdown,
      orderItemModal,
      showCustomerModal,
      rmethods,
      ORDER_ITEM_STATUSDropDonw,
      locationModal,
      showDeleteModal,
      qtytotal,
      lentotal,
      weighttotal,
      isTripEditable,
      textMessage,
      CustomerDropdonw,
      DispatcherDropdown,
      currentBoxAddress,
      Place,
      BrokerDropdown,
      SalesDropdown,
      OrderDropdonw,
      TrapDropdonw,
      PackgDropdonw,
      UnitDropdonw,
      FuelSurchageDropdonw,
      ReatMethodDropdonw,
      errors,
      tax_label,
      orderValue,
      ImageDisplay,
      PromptPrevent,
      CSModal,
      CSMessage,
      showConfirmation,
      confirmationMessage,
      confirmationRfMessage,
      oderlist
    } = this.state;

    const {
      sales_byContact,
      formOption,
      brokerContact,
      dispatch_byContact,
      consignValue,
      access,
      bool,
    } = this.state;

    const showOrderItemModal = Boolean(
      orderItemModal >= 0 && orderItemModal !== false
    );
    const showLocationModal = Boolean(
      locationModal >= 0 && locationModal !== false
    );
    const noteValue =
      consignValue &&
      showOrderItemModal &&
      consignValue[orderItemModal]['note'];
    const addressValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['location'];
    const postalValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['postal_code'];
    const cityValue =
      consignValue && showLocationModal && consignValue[locationModal]['city'];
    const provenceValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['province'];
    const loc_nmValue =
      consignValue &&
      showLocationModal &&
      consignValue[locationModal]['loc_nm'];
    const isUrgentOrder = this.state.orderValue.urgent;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />
        <NotificationMessage
          notifyBoll={formOption.notifyBoll}
          notifyMessage={formOption.Message}
        />
        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb" style={{ display: "flex", flexDirection: "row" }}>
              <h3>
                {this.props.match !== undefined &&
                  this.props.match !== '' &&
                  this.props.match.params.id !== '' &&
                  this.props.match.params.id !== undefined
                  ? 'Update Orders'
                  : 'New Orders'}
              </h3>
              {(isUrgentOrder === true || isUrgentOrder === "Yes") &&
                <Badge value="Urgent" className="mr-2" size="small" severity="warning" />}
            </div>

            <div className="form">
              <Col sm="12">
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['billed_to'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Bill to Customer*
                      </Form.Label>
                      <Col sm="8">
                        <Autosuggestion
                          name="billed_to_name"
                          placeholder="Search customer..."
                          value={orderValue.billed_to_name}
                          onChange={this.handleChangeGen}
                          ref={this.wrapperRef}
                          style={{ display: bool ? 'block' : 'none' }}
                          bool={bool}
                          getAutosuggest={CustomerDropdonw}
                          onClick={(id, name) =>
                            this.setAutosuggestId(id, name)
                          }
                        />
                        {/* <SelectSearch
                          container="rowselect"
                          search
                          options={CustomerDropdonw}
                          select="test"
                          name="billed_to"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'billed_to')
                          }
                          value={orderValue.billed_to}
                          placeholder="Type to search"
                        /> */}

                        <div
                          className="addCustomerbtn"
                          onClick={() => this.toggleAddCustomerModal(false)}
                        >
                          +
                        </div>
                        <span
                          className={
                            errors['billed_to'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['billed_to']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['order_date'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Date
                      </Form.Label>
                      <Col sm="4">
                        <DatePicker
                          selected={orderValue.order_date}
                          dateFormat="dd-MM-yyyy hh:mm aa"
                          showTimeSelect
                          className="form-control inpcalendar"
                          popperPlacement="start"
                          name="order_date"
                          autoComplete="off"
                          value={orderValue.order_date}
                          onChange={(date) =>
                            this.handleDate(date, 'order_date')
                          }
                        />
                        <span
                          className={
                            errors['order_date'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['order_date']}
                        </span>
                      </Col>
                      <Col sm={5}>
                        <Form.Label className="text-left bold">
                          Order No# {orderValue.order_no}
                        </Form.Label>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Broker
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={BrokerDropdown}
                              select="test"
                              name="broker"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'broker')
                              }
                              value={orderValue.broker}
                              placeholder="Type to search"
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Contact Person
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col sm="12">
                            <Form.Control
                              disabled
                              placeholder="Contact Person Name"
                              name="brkname"
                              value={brokerContact.name}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['address1'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['address1']}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              placeholder="Phone Number"
                              name="brkphone"
                              type="number"
                              value={brokerContact.phone}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['country_id']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['country_id']}
                            </span>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              placeholder="Email"
                              disabled
                              name="brkemail"
                              value={brokerContact.email}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['state_id'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['state_id']}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="3">
                            <Form.Group>
                              <Form.Label className="text-right">
                                Urgent Order
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm="2">
                            <Form.Group as={Row} className="centercheck">
                              <Form.Check
                                type="radio"
                                onClick={this.handleChangeGen}
                                checked={
                                  orderValue.urgent === 'Yes' ||
                                  orderValue.urgent === true
                                }
                                value="Yes"
                                id="Yes"
                                htmlFor="Yes"
                                name="urgent"
                                label="Yes"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm="2">
                            <Form.Group as={Row} className="centercheck">
                              <Form.Check
                                type="radio"
                                onClick={this.handleChangeGen}
                                value="No"
                                id="No"
                                htmlFor="No"
                                checked={
                                  orderValue.urgent === 'No' ||
                                  orderValue.urgent === false
                                }
                                name="urgent"
                                label="No"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>


                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Custom Broker
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              placeholder="Custom Broker"
                              name="cstm_broker"
                              value={orderValue.cstm_broker}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Contact Person
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col sm="6">
                            <Form.Control
                              placeholder="Contact Person Name"
                              name="broker_cnt_name"
                              value={orderValue.broker_cnt_name}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              placeholder="Fax"
                              type="number"
                              name="broker_cnt_fax"
                              value={orderValue.broker_cnt_fax}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <Form.Control
                              placeholder="Email"
                              name="broker_cnt_email"
                              value={orderValue.broker_cnt_email}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              placeholder="Phone"
                              type="number"
                              name="broker_cnt_phone"
                              value={orderValue.broker_cnt_phone}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group
                          className={
                            errors['dispatch_by'] ? 'errorshows' : 'errorshides'
                          }
                          as={Row}
                        >
                          <Form.Label column sm="3" className="text-right">
                            Dispatcher
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={DispatcherDropdown}
                              select="test"
                              name="dispatch_by"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'dispatch_by')
                              }
                              value={orderValue.dispatch_by}
                              placeholder="Type to search"
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Contact Person
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col sm="12">
                            <Form.Control
                              disabled
                              placeholder="Contact Person Name"
                              name="address1"
                              value={dispatch_byContact.name}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['address1'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['address1']}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              placeholder="Phone Number"
                              name="cp"
                              value={dispatch_byContact.phone}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['country_id']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['country_id']}
                            </span>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              placeholder="Email"
                              name="emailcp"
                              value={dispatch_byContact.email}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['state_id'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['state_id']}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group
                          className={
                            errors['sales_by'] ? 'errorshows' : 'errorshides'
                          }
                          as={Row}
                        >
                          <Form.Label column sm="3" className="text-right">
                            Sales By
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={SalesDropdown}
                              select="test"
                              name="sales_by"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'sales_by')
                              }
                              value={orderValue.sales_by}
                              placeholder="Type to search"
                            />
                            <span
                              className={
                                errors['sales_by'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['sales_by']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group
                          className={
                            errors['order_cur'] ? 'errorshows' : 'errorshides'
                          }
                          as={Row}
                        >
                          <Form.Label column sm="3" className="text-right">
                            Currency*
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={CurDropdown}
                              select="test"
                              name="order_cur"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'order_cur')
                              }
                              value={orderValue.order_cur}
                              placeholder="Type to search"
                            />
                            <span
                              className={
                                errors['order_cur'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['order_cur']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Note
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              className={
                                errors['sales_note']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                              placeholder="Notes if any"
                              name="sales_note"
                              value={orderValue.sales_note}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['sales_note']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['sales_note']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Contact Person
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col sm="12">
                            <Form.Control
                              disabled
                              placeholder="Contact Person Name"
                              name="sname"
                              value={sales_byContact.name}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              placeholder="Phone Number"
                              name="salphone"
                              value={sales_byContact.phone}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              placeholder="Email"
                              name="salemail"
                              value={sales_byContact.email}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['order_type'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Order Type*
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={OrderDropdonw}
                          select="test"
                          name="order_type"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'order_type')
                          }
                          value={orderValue.order_type}
                          placeholder="Type to search"
                        />
                        <span
                          className={
                            errors['order_type'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['order_type']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Ref/PO Number
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="ref_no"
                          value={orderValue.ref_no}
                          onChange={this.handleChangeGen}
                        />
                        <span
                          className={
                            errors['ref_no'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['ref_no']}
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Equipment Type
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col md="6">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={EQUIP_TYPEDropdown}
                              select="test"
                              name="equip_type"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'equip_type')
                              }
                              value={orderValue.equip_type}
                              placeholder="Type to search"
                            />
                          </Col>
                          <Col md="6">
                            <Form.Control
                              name="equip_info"
                              value={orderValue.equip_info}
                              placeholder="Equipment Info"
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['equip_info']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['equip_info']}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="text-right">
                        Commodity
                      </Form.Label>
                      <Col sm="8">
                        <Row>
                          <Col md="6">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={COMM_TYPEDropdown}
                              select="test"
                              name="comm_type"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'comm_type')
                              }
                              value={orderValue.comm_type}
                              placeholder="Type to search"
                            />
                          </Col>

                          <Col md="6">
                            <Form.Control
                              name="comm_info"
                              value={orderValue.comm_info}
                              placeholder="Commodity Info"
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['comm_info'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['comm_info']}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="3">
                        <Form.Group>
                          <Form.Label className="text-right">
                            Load Type*
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onClick={this.handleChangeGen}
                            checked={
                              orderValue.load_type === 'FL' ||
                              orderValue.load_type === true
                            }
                            value="FL"
                            id="FL"
                            htmlFor="FL"
                            name="load_type"
                            label="FL"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onClick={this.handleChangeGen}
                            value="LTL"
                            id="LTL"
                            htmlFor="LTL"
                            checked={
                              orderValue.load_type === 'LTL' ||
                              orderValue.load_type === true
                            }
                            name="load_type"
                            label="LTL"
                          />
                        </Form.Group>
                      </Col>

                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onClick={this.handleChangeGen}
                            value="oversized"
                            checked={
                              orderValue.load_type === 'oversized' ||
                              orderValue.load_type === true
                            }
                            name="load_type"
                            id="oversized"
                            htmlFor="oversized"
                            label="Oversized"
                          />
                        </Form.Group>
                      </Col>

                      <Col sm="2">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="radio"
                            onClick={this.handleChangeGen}
                            value="unknown"
                            id="unknown"
                            htmlFor="unknown"
                            checked={
                              orderValue.load_type === 'unknown' ||
                              orderValue.load_type === true
                            }
                            name="load_type"
                            label="Unknown"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3"></Col>
                      <Col md="6">
                        {' '}
                        <span
                          className={
                            errors['load_type'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['load_type']}
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group
                          className={
                            errors['order_cur'] ? 'errorshows' : 'errorshides'
                          }
                          as={Row}
                        >
                          <Form.Label column sm="3" className="text-right">
                            Order Status
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              disabled={isTripEditable}
                              options={OrderStatusDropdonw}
                              select="test"
                              name="order_status"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(
                                  val,
                                  'order_status'
                                )
                              }
                              value={orderValue.order_status}
                              placeholder="Type to search"
                            />
                            <span
                              className={
                                errors['order_status']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['order_status']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Attachment
                          </Form.Label>
                          <Col sm="8">
                            <Form.File
                              custom
                              type="file"
                              name="files"
                              label="Upload files"
                              onChange={this.handleChangeGenFile}
                            />
                            <div className="filetoupload">
                              <ul>
                                {ImageDisplay &&
                                  ImageDisplay.length > 0 &&
                                  ImageDisplay.map((item, i) => (
                                    <li>
                                      <span className="imgName">
                                        {' '}
                                        {item.name}
                                      </span>
                                      <span className="imgHolder">
                                        {item.type === 'application/pdf' ? (
                                          <img src={PDF_ICON} alt="img" />
                                        ) : (
                                          <img src={IMG_ICON} alt="img" />
                                        )}
                                      </span>
                                      <span
                                        className="remove"
                                        onClick={() =>
                                          this.handleRemoveFile(item, i)
                                        }
                                      >
                                        x
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </Col>
                        </Form.Group>
                        <div className="clr"></div>
                        <div className="filetoupload_list">
                          <ul>
                            {ImageAddedS3 &&
                              ImageAddedS3.length > 0 &&
                              ImageAddedS3.map((item, index) =>
                                item.status !== 'N' ? (
                                  <li>
                                    {item.name !== undefined ? (
                                      <span className="imgName">
                                        {' '}
                                        {item.name}
                                      </span>
                                    ) : null}
                                    {item.url !== undefined ? (
                                      <span
                                        className="downicon"
                                        onClick={() =>
                                          this.getImageDownload(
                                            item.url,
                                            item.name
                                          )
                                        }
                                      >
                                        Download
                                      </span>
                                    ) : null}
                                    <span
                                      className="remove"
                                      onClick={() => this.handleS3Remove(index)}
                                    >
                                      x
                                    </span>
                                  </li>
                                ) : null
                              )}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Form.Group
                      className={
                        errors['tarp_type'] ? 'errorshows' : 'errorshides'
                      }
                      as={Row}
                    >
                      <Form.Label column sm="3" className="text-right">
                        Tarp Type
                      </Form.Label>
                      <Col sm="8">
                        <SelectSearch
                          container="rowselect"
                          search
                          options={TrapDropdonw}
                          select="test"
                          name="tarp_type"
                          autoComplete="sdf"
                          onChange={(val) =>
                            this.handleSeachableDropdonw(val, 'tarp_type')
                          }
                          value={orderValue.tarp_type}
                          placeholder="Type to search"
                        />
                        <span
                          className={
                            errors['tarp_type'] ? 'errorshow' : 'errorshide'
                          }
                        >
                          {errors['tarp_type']}
                        </span>
                      </Col>
                    </Form.Group>

                    <Row className="mrt25">
                      <Form.Label column sm="3" className="text-right">
                        Material Type
                      </Form.Label>

                      <Col sm="4">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="checkbox"
                            onChange={this.handleChangeGenCheckedCB}
                            name="mat_typ_haz"
                            id="mat_typ_hazs"
                            htmlFor="mat_typ_hazs"
                            label="Hazmat"
                            value={orderValue.mat_typ_haz}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="4">
                        <Form.Group as={Row} className="centercheck">
                          <Form.Check
                            type="checkbox"
                            onChange={this.handleChangeGenCheckedCB}
                            name="mat_typ_ref"
                            id="mat_typ_ref"
                            htmlFor="mat_typ_ref"
                            label="Reefer"
                            value={orderValue.mat_typ_ref}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            CSA Compliance
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="select"
                              name="csa_compliant"
                              value={orderValue.csa_compliant}
                              onChange={this.handleChangeGen}
                            >
                              <option disabled>select..</option>

                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="bdrb">
                  {consignValue.length > 0 ? (
                    <div className="Tablegrid">
                      <div className="listtopbar">
                        <div className="type">Type</div>
                        <div className="location2">Location(*)</div>
                        <div className="date2">Request Date</div>
                        <div className="pkgtype">Package Type</div>
                        <div className="qty">Qty</div>
                        <div className="qty">Length</div>
                        <div className="qty">Weight</div>
                        <div className="unitbox">Unit</div>
                        <div className="info">P/D#</div>
                        <div className="desc">Appt(*)</div>

                        <div className="unitbox">Item Status</div>
                        <div className="notes">Notes</div>
                      </div>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {this.state.consignValue.map((x, i) => (
                                <Draggable
                                  key={i}
                                  draggableId={i.toString()}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      {x.status === true ? (
                                        <div className="listdata" key={i}>
                                          <div
                                            className="type"
                                            {...provided.dragHandleProps}
                                          >
                                            <img
                                              src={dragicon}
                                              className="btndrag"
                                              alt="drag"
                                            />
                                            {x.type}
                                            <div className="locInfo">
                                              i
                                              <div className="fulladdress">
                                                {x.type === 'C'
                                                  ? 'Consignee'
                                                  : 'Shipper'}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="location2">
                                            {x.location !== '' ? (
                                              <div className="locInfo">
                                                i
                                                <div className="fulladdress">
                                                  <p>
                                                    <strong>C/S Name :</strong>{' '}
                                                    {x.loc_nm}
                                                  </p>
                                                  <p>
                                                    <strong>Address :</strong>{' '}
                                                    {x.location}
                                                  </p>
                                                  <p>
                                                    <strong>
                                                      Postal Code :
                                                    </strong>{' '}
                                                    {x.postal_code}
                                                  </p>
                                                </div>
                                              </div>
                                            ) : null}

                                            <Form.Control
                                              placeholder="search location"
                                              name="location"
                                              value={x.location}
                                              type="text"
                                              onClick={() =>
                                                this.toggleLocationModal(i)
                                              }
                                            // onChange={(e) =>
                                            //   this.handleChange(e, i)
                                            // }
                                            />
                                            <Form.Control
                                              hidden
                                              name="postal_code"
                                              value={x.postal_code}
                                            />
                                          </div>
                                          <div className="date2">
                                            <DatePicker
                                              selected={x.req_date}
                                              dateFormat="dd-MM-yyyy hh:mm:ss"
                                              className="form-control inpcalendars"
                                              popperPlacement="start"
                                              showTimeSelect
                                              locale="en-GB"
                                              name="req_date"
                                              autoComplete="off"
                                              value={x.req_date}
                                              onChange={(date) =>
                                                this.handleDateConsig(
                                                  date,
                                                  'req_date',
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="pkgtype">
                                            <Form.Control
                                              as="select"
                                              name="pkg_type"
                                              value={x.pkg_type}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>
                                              {PackgDropdonw.map((item) => (
                                                <option
                                                  key={item.value}
                                                  value={item.value}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                            </Form.Control>
                                          </div>
                                          <div className="qty">
                                            <Form.Control
                                              name="qty"
                                              value={x.qty}
                                              type="number"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="qty">
                                            <Form.Control
                                              name="length"
                                              value={x.length}
                                              type="number"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="qty">
                                            <Form.Control
                                              name="weight"
                                              value={x.weight}
                                              type="number"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="unitbox">
                                            <Form.Control
                                              as="select"
                                              name="unit"
                                              value={x.unit}
                                              type="number"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>
                                              {UnitDropdonw.map((item) => (
                                                <option
                                                  key={item.value}
                                                  value={item.value}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                            </Form.Control>
                                          </div>

                                          <div className="info">
                                            <Form.Control
                                              name="info"
                                              value={x.info}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            />
                                          </div>
                                          <div className="desc">
                                            <Form.Control
                                              as="select"
                                              name="apnt_type"
                                              value={x.apnt_type}
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>

                                              {APPNT_TYPEDropdown.map(
                                                (item) => (
                                                  <option
                                                    key={item.value}
                                                    value={item.value}
                                                  >
                                                    {item.name}
                                                  </option>
                                                )
                                              )}
                                            </Form.Control>
                                          </div>

                                          <div className="unitbox">
                                            <Form.Control
                                              as="select"
                                              disabled
                                              name="item_status"
                                              value={x.item_status}
                                              type="number"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                            >
                                              <option disabled>select..</option>
                                              {ORDER_ITEM_STATUSDropDonw.map(
                                                (item) => (
                                                  <option
                                                    key={item.value}
                                                    value={item.value}
                                                  >
                                                    {item.name}
                                                  </option>
                                                )
                                              )}
                                            </Form.Control>
                                          </div>
                                          <div className="notes">
                                            {x.note !== '' ? (
                                              <div className="locInfo">
                                                i
                                                <div className="fulladdress">
                                                  <p>{x.note}</p>
                                                </div>
                                              </div>
                                            ) : null}
                                            <img
                                              src={IMG_NOTE_ICON}
                                              alt="note icon"
                                              onClick={() =>
                                                this.toggleOrderItemNoteModal(i)
                                              }
                                            />

                                            {orderValue.trip != null ? (
                                              <img
                                                className="delete"
                                                src={deletebtn}
                                                alt="Delete"
                                              />
                                            ) : (
                                              <img
                                                className="delete"
                                                onClick={() =>
                                                  this.removeOrderItem(i, x.id)
                                                }
                                                src={deletebtnred}
                                                alt="Delete"
                                              />
                                            )}

                                            <div className="moremenu">
                                              <img
                                                src={default_menu}
                                                alt="More"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  ) : null}

                  <div className="btnData">
                    <div className="btnbox">
                      <Button
                        onClick={() => this.handleAddClick('C')}
                        className="primary-button"
                      >
                        + Consignee
                      </Button>
                      <Button
                        onClick={() => this.handleAddClick('S')}
                        className="sec-button"
                      >
                        + Shipper
                      </Button>
                    </div>
                    <div className="databox">
                      <div className="boxuint">
                        <Form.Control value={qtytotal} disabled />
                        <Form.Control value={lentotal} disabled />
                        <Form.Control value={weighttotal} disabled />
                      </div>
                      <div className="miletotal">
                        <div className="custom">
                          <Form.Control
                            as="select"
                            name="miletcaltype"
                            value={orderValue.miletcaltype}
                            onChange={(e) => this.handleChangeGen(e)}
                          >
                            <option value="Auto">Auto</option>
                            <option value="Manual">Manual</option>
                          </Form.Control>
                        </div>

                        {orderValue.miletcaltype === 'Auto' ? (
                          <div className="custom">
                            <Button
                              onClick={() => this.handleCallMiles()}
                              className="sec-button"
                            >
                              Calc Miles
                            </Button>
                          </div>
                        ) : null}
                        <label>Total</label>

                        <Form.Control
                          value={milestotal}
                          onChange={this.handleChangeGenMile}
                          disabled={orderValue.miletcaltype === 'Auto'}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="bdrb pd20">
                  <Col md={3} className="text-left"></Col>
                  <Col md={9}>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={10}>
                        <Col md={12}></Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="bdrb">
                  <Col md={3}></Col>
                  <Col md={9}>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col sm="6">
                            <Form.Group
                              className={
                                errors['rate_mthd']
                                  ? 'errorshows'
                                  : 'errorshides'
                              }
                              as={Row}
                            >
                              <Form.Label column sm="4" className="text-right">
                                Rate Method
                              </Form.Label>
                              <Col sm="8">
                                <SelectSearch
                                  container="rowselect"
                                  search
                                  options={ReatMethodDropdonw}
                                  select="test"
                                  name="rate_mthd"
                                  autoComplete="sdf"
                                  onChange={(val) =>
                                    this.handleSeachableDropdonw(
                                      val,
                                      'rate_mthd'
                                    )
                                  }
                                  value={orderValue.rate_mthd}
                                  placeholder="Select Tax"
                                />
                                <span
                                  className={
                                    errors['rate_mthd']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['rate_mthd']}
                                </span>
                              </Col>
                            </Form.Group>
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="6">
                                <Form.Control
                                  placeholder="Freight Cost Per Mile"
                                  disabled={
                                    rmethods !== 'PERMILE' ? true : false
                                  }
                                  name="frt_cost_per_mile"
                                  value={orderValue.frt_cost_per_mile}
                                  onChange={this.handleChangeGen}
                                />
                              </Col>
                              <Col sm="6">
                                <Form.Group as={Row}>
                                  <Form.Label
                                    column
                                    sm="6"
                                    className="text-right"
                                  >
                                    Freight Cost
                                  </Form.Label>
                                  <Col sm="6">
                                    <Form.Control
                                      disabled={
                                        rmethods === 'PERMILE' ? true : false
                                      }
                                      name="frt_cost"
                                      value={orderValue.frt_cost}
                                      onChange={this.handleChangeGen}
                                    />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <Form.Group
                              className={
                                errors['surcharge_typ']
                                  ? 'errorshows'
                                  : 'errorshides'
                              }
                              as={Row}
                            >
                              <Form.Label column sm="4" className="text-right">
                                Fuel Surcharge
                              </Form.Label>
                              <Col sm="8">
                                <SelectSearch
                                  container="rowselect"
                                  search
                                  options={FuelSurchageDropdonw}
                                  select="test"
                                  name="surcharge_typ"
                                  autoComplete="sdf"
                                  onChange={(val) =>
                                    this.handleSeachableDropdonw(
                                      val,
                                      'surcharge_typ'
                                    )
                                  }
                                  value={orderValue.surcharge_typ}
                                  placeholder="Select Surchage"
                                />
                                <span
                                  className={
                                    errors['surcharge_typ']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['surcharge_typ']}
                                </span>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <Row>
                              <Col sm="6">
                                <Form.Control
                                  placeholder="0.00"
                                  name="custom_surcharge"
                                  value={orderValue.custom_surcharge}
                                  onChange={this.handleChangeGen}
                                />
                              </Col>
                              <Col sm="6">
                                <Form.Control
                                  className={
                                    errors['surcharge']
                                      ? 'errorshow2'
                                      : 'errorshide2'
                                  }
                                  placeholder="0.00"
                                  name="surcharge"
                                  disabled
                                  value={orderValue.surcharge}
                                  onChange={this.handleChangeGen}
                                />
                                <span
                                  className={
                                    errors['surcharge']
                                      ? 'errorshow'
                                      : 'errorshide'
                                  }
                                >
                                  {errors['surcharge']}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <Form.Group as={Row}>
                              <Form.Label column sm="4" className="text-right">
                                Extra Charge
                              </Form.Label>
                              <Col sm="8">
                                <Form.Control
                                  placeholder="0.00"
                                  name="extra_cost"
                                  value={orderValue.extra_cost}
                                  onChange={this.handleChangeGen}
                                />
                              </Col>
                            </Form.Group>
                          </Col>

                          <Col sm="6">
                            <Form.Control
                              placeholder="Enter Details if any"
                              name="extra_cost_desc"
                              value={orderValue.extra_cost_desc}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="5"></Col>
                      <Col sm="7">
                        <Form.Group as={Row}>
                          <Form.Label column sm="5" className="text-right">
                            Total Before Tax
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              className={
                                errors['beforetax']
                                  ? 'errorshow2'
                                  : 'errorshide2'
                              }
                              placeholder="0.00"
                              disabled
                              name="beforetax"
                              value={orderValue.beforetax}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['beforetax'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['beforetax']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <Form.Group
                          as={Row}
                          className={
                            errors['tax_type'] ? 'errorshows' : 'errorshides'
                          }
                        >
                          <Form.Label column sm="3" className="text-right">
                            Tax
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={taxList}
                              select="test"
                              name="tax_type"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'tax_type')
                              }
                              value={orderValue.tax_type}
                              placeholder="Select Tax"
                            />
                            <span
                              className={
                                errors['tax_type'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['tax_type']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group as={Row}>
                          <Col sm="6">
                            <Form.Control
                              disabled={
                                tax_label.includes('CustomTax') ? false : true
                              }
                              placeholder="0.00"
                              name="custom_tax_total"
                              value={orderValue.custom_tax_total}
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              className={
                                errors['tax_total']
                                  ? 'errorshow2'
                                  : 'errorshide2'
                              }
                              placeholder="0.00"
                              name="tax_total"
                              value={orderValue.tax_total.toFixed(2)}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['tax_total'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['tax_total']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="bdrb ">
                  <Col md={12} className="text-right totalvalue">
                    Total : <span>{orderValue.total}</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="text-right">
                    <Link to="/fleet/orders">
                      <Button className="secondary-button">Cancel</Button>
                    </Link>
                    <Button
                      onClick={this.handleReset}
                      className="secondary-button"
                    >
                      Reset
                    </Button>

                    {(this.props.match.params.id &&
                      access &&
                      access.orderMenu &&
                      access.orderMenu.update === true) ||
                      (!this.props.match.params.id &&
                        access &&
                        access.orderMenu &&
                        access.orderMenu.create === true) ? (
                      <Button
                        onClick={
                          isTripEditable === false ? this.handleSave : ''
                        }
                        className="primary-button"
                      >
                        {isTripEditable === false
                          ? 'Save'
                          : 'You can not update order'}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>

        <Modal
          onHide={() => this.toggleOrderItemNoteModal(false)}
          show={showOrderItemModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <textarea
                    className="default-input"
                    name="note"
                    value={noteValue}
                    onChange={this.onChangeOrderItem(orderItemModal)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleOrderItemNoteModal(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => this.toggleOrderItemNoteModal(false)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          id="CustomerRecord"
          onHide={() => this.toggleAddCustomerModal(false)}
          show={showCustomerModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateCustomer />
          </Modal.Body>
        </Modal>

        {/* Location  Modal */}
        <Modal
          onHide={() => this.toggleLocationModal(false)}
          show={showLocationModal}
        >
          <Modal.Header>
            <Modal.Title>Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12 addaddress">
                  <div className="addblock">
                    <p>C/S Name*</p>
                    {<LoctionName type="ord" index={locationModal} value={loc_nmValue} onChangeOrderItem={this.onChangeOrderItem} selectedLocation={this.state.selectedLocation} />}
                    <p>Address*</p>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="location"
                      autoComplete="sfsdfsdfsf"
                      value={addressValue}
                      onChange={this.onChangeOrderItem(locationModal)}
                    />
                    {currentBoxAddress === true ? (
                      <div className="addressdata">
                        <ul>
                          {Place.map((item) => (
                            <li
                              onClick={() =>
                                this.setAddress(
                                  item.formatted_address,
                                  item.place_id
                                )
                              }
                              key={item.id}
                            >
                              {item.formatted_address}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  <p>Postal Code*</p>
                  <Form.Control
                    name="postal_code"
                    value={postalValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />

                  <p>City*</p>
                  <Form.Control
                    name="city"
                    value={cityValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />
                  <p>Province*</p>
                  <Form.Control
                    name="province"
                    value={provenceValue}
                    onChange={this.onChangeOrderItem(locationModal)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="secondary-button"
              onClick={() => this.toggleLocation(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => this.toggleLocationModal(false, 'save')}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <PopupLocConfirm
          isOpen={CSModal}
          onConfirm={this.getNotifiyCSConfirm}
          textMessage={CSMessage}
          toggleShow={this.handleModalNotifiyCSIsEqual}
        />
        <PopupRefConfirm
          isOpen={showRefConfirmation}
          onConfirm={this.getRefConfirm}
          textMessage={confirmationRfMessage}
          toggleShow={this.handleModalRefNo}
          data={oderlist}
        />
        <PopupLocConfirm
          isOpen={showConfirmation}
          onConfirm={this.handleConfirmationClick}
          textMessage={confirmationMessage}
          toggleShow={this.toggleConfirmationModal}
        />


        <Popup
          isOpen={showDeleteModal}
          onConfirm={this.handleRemoveClick}
          textMessage={textMessage}
          toggleShow={this.toggleDeleteModal}
        />
        {PromptPrevent ? (
          <Prompt
            message={() =>
              'Moving away from this page will remove any unsaved data. Are you sure?'
            }
          />
        ) : null}
      </div>
    );
  }
}

export default CreateOrders;