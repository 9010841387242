import React, { Component } from 'react';
import { Button, Col, Table, Form, Row } from 'react-bootstrap';
import SnackbarError from '../../common/SnackbarError';
import SnackbarSuccess from '../../common/SnackbarSuccess';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import default_menu from '../../images/default_menu.svg';
import {
  deleteApi,
  formatDateyyyyMMdd,
  getApiAsyn,
  getApiAsynBlob,
  loadierShow,
  loadingsShow,
} from '../../Services/PostAPI';
import { Popup } from '../../common/Popup';
import { Filter } from './Filter';
import { ModalPopup } from '../../common/ModalPopup';
const cookies = new Cookies();
let istSearchList = '';
const noop = () => {}
const DELETE_ORDER_RECORD_MESSAGE_FOR_NEW_ORDER = 'This will delete order. Do you want to continue ?';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortby:'desc',
      key:'order_no',
      access: JSON.parse(localStorage.getItem('menuAccess')),
      PageNumber: 1,
      PageSize: 10,
      pageCount: 0,
      sort: {},
      Data: [],
      listData: [],
      formOption: {},
      deleteModal: false,
      itemtodelete: '',
      filterboll: true,
      openRowIndex: false,
      bool: false,
      textMessage: DELETE_ORDER_RECORD_MESSAGE_FOR_NEW_ORDER,
      tripCreationModal: false,
      statusValue: '',
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleSortBy =(val)=>{
    const {filterboll} =this.state;
   this.setState((prevState) => {
    let { sortby, key } = prevState;
    sortby=sortby==='asc' ? 'desc':'asc'
    key = val;
    return {sortby, key}
   },()=>{
    if(filterboll===false){
      this.getOrdersData();
      }
      else{
        this.getFilterOrder()
      }
   })
  }
  
  handleTripCreationModal = (id) => {
    this.setState((prevState) => {
      let { tripCreationModal, orderId } = prevState;
      tripCreationModal = prevState.tripCreationModal === false ? true : false;
      orderId = id;
      return { tripCreationModal, orderId };
    });
  };

  setWrapperRef(node) {
    this.WrapperRef = node;
  }
  handleClickOutside(event) {
    this.setState({
      bool: false,
    });
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      alert('You clicked outside of me!');
    }
  }
  handleChangeGenCustomer = (event) => {
    const { sort } = this.state;
    let { name, value } = event.target;
    sort[name] = value;
    this.setState({
      [sort]: sort,
    });

    if (name === 'ids') {
      this.getAutosuggest(value);
    }
    if (name === 'ids' && value === '') {
      this.setState(
        (prevState) => {
          let { sort } = prevState;
          sort.id = '';
          sort.ids = '';
          return { sort };
        },
        () => {
          this.getFilterOrder();
        }
      );
    }
  };
  getAutosuggest = async (id) => {
    let ddbrokder = [];
    if (id !== undefined && id.length > 2) {
      let res = await getApiAsyn(
        `customers?intent=suggest&begin-with=${id}`,
        cookies.get('authorization')
      );
      if (res && res.length > 0) {
        this.setState({
          bool: true,
        });
        ddbrokder = res;
        ddbrokder = ddbrokder.map((item) => ({
          name: '(' + item.id + ') ' + item.name,
          value: item.id,
        }));
        this.setState({
          listData: ddbrokder,
        });
      } else {
        this.setState({
          listData: [],
        });
      }
    }
  };

  setAutosuggestId = (id, name) => {
    this.setState(
      {
        sort: {
          ...this.state.sort,
          ids: name,
          id: id,
        },
        bool: false,
      },
      () => {
        this.getFilterOrder();
      }
    );
  };
  handleFilter = () => {
    this.setState((prevState) => {
      let { filterboll, sort } = prevState;
      filterboll = prevState.filterboll === false ? true : false;
      if (filterboll === false) {
        sort = {};
        this.getOrdersData();
      }
      return { filterboll, sort };
    });
  };
  handleChangeGenChecked = (event) => {
    const { sort } = this.state;
    let { name, checked } = event.target;
    sort[name] = checked;
    this.setState({
      [sort]: sort,
    });

    this.getFilterOrder();
    console.log(sort);
  };
  handleChangeGen = (event) => {
    const { sort } = this.state;
    let { name, value } = event.target;
    sort[name] = value;
    this.setState({
      [sort]: sort,
    });

    this.getFilterOrder();
    console.log(sort);
  };

  handleSortDropdonw = (val, name) => {
    const { sort } = this.state;
    sort[name] = val;
    this.setState({
      [sort]: sort,
    });

    this.getFilterOrder();
    console.log(sort);
  };

  handleSortDate = (date, name) => {
    const { sort } = this.state;
    sort[name] = date;
    this.setState({
      ...sort,
      [sort]: sort,
    });
    this.getFilterOrder();
    console.log(sort);
  };
  handleDeleteModal = (id, status) => {
    this.setState((prevState) => {
      let { deleteModal, itemtodelete, statusValue, textMessage } = prevState;
      deleteModal = prevState.deleteModal === false ? true : false;
      itemtodelete = id;
      statusValue = status;
      if (status !== 'New') {
        textMessage =
          'You can not delete this order because order is associated with trip';
      } else if (status === 'New') {
        textMessage = DELETE_ORDER_RECORD_MESSAGE_FOR_NEW_ORDER;
      }
      return { deleteModal, itemtodelete, statusValue, textMessage };
    });
  };

  handleRecordDelete = () => {
    let { itemtodelete } = this.state;
    deleteApi(`orders/${itemtodelete}`, cookies.get('authorization')).then(
      (res) => {
        if (res.status === 200) {
          this.setState(
            {
              legalBoll: true,
              successMessage: 'Record deleted successfully',
            },
            function () {
              this.handleDeleteModal();
              setTimeout(
                function () {
                  this.setState({
                    legalBoll: false,
                  });
                }.bind(this),
                3000
              );
            }
          );
          this.getOrdersData();
        }
      }
    );
  };
  handleCreateTrip = () => {
    const { orderId } = this.state;
    this.props.history.push({
      pathname: '/fleet/trip/add-new',
      state: { order_id: orderId },
    });
  };
  getTripByOrderId = async (id) => {
    let res = await getApiAsyn(
      `trips?intent=search&paged=true&page=1&size=10&order_id=${id}`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      console.log(res);
      if (res.page.total_elements > 0) {
        this.props.history.push({
          pathname: `/fleet/ordertrips/${id}/view`,
        });
      } else {
        this.handleTripCreationModal(id);

        // setTimeout(() => {
        //   this.props.history.push({
        //     pathname: '/fleet/trip/add-new',
        //     state: { order_id: id },
        //   });
        // }, 2000);
      }
    }
  };

  getFilterOrder = async () => {
    const { PageNumber, PageSize, sort, key, sortby } = this.state;
    if (sort === undefined) {
      this.handelMessage('Please select customer to filter', 'errorBoll');
      return false;
    } else {
      let id = sort.id !== undefined && sort.id !== '' ? '&id=' + sort.id : '';
      id +=
        sort.date1 !== undefined
          ? '&date1=' + formatDateyyyyMMdd(sort.date1)
          : '';
      id +=
        sort.date2 !== undefined
          ? '&date2=' + formatDateyyyyMMdd(sort.date2)
          : '';
      id +=
        sort.load_type !== undefined && sort.load_type !== '-1'
          ? '&load=' + sort.load_type
          : '';
      id += sort.order_no !== undefined ? '&order_no=' + sort.order_no : '';
      id += sort.refno !== undefined ? '&refno=' + sort.refno : '';

      id += sort.address !== undefined ? '&loc=' + sort.address : '';
      id +=
        sort.order_status !== undefined && sort.order_status !== '-1'
          ? '&order_status=' + sort.order_status
          : sort.order_status === -1
          ? ''
          : '';
      id +=
        sort.order_type !== undefined && sort.order_type !== '-1'
          ? '&order_type=' + sort.order_type
          : '';

      id +=
        sort.eqp_type !== undefined && sort.eqp_type !== '-1'
          ? '&equip_type=' + sort.eqp_type
          : '';

      id += sort.reefer !== undefined ? '&reefer=' + sort.reefer : '';
      id += (sort.urgent !== undefined) && (sort.urgent !== false) ? '&urgent=' + sort.urgent : '';
      id +=
        sort.comodity_type !== undefined && sort.comodity_type !== '-1'
          ? '&comm_type=' + sort.comodity_type
          : '';
      if (id !== '') {
        let res = await getApiAsyn(
          `orders?intent=search&paged=true&page=${PageNumber}&size=${PageSize}${id}&sort=${key},${sortby}`,
          cookies.get('authorization')
        );
        console.log('FL D', res);
        istSearchList = res && res.page && res.page.total_elements;
        if (res && istSearchList > 0 && res.status !== false) {
          this.setState({
            Data: res._embedded.content,
            pageCount: istSearchList / PageSize,
          });
        } else {
          this.setState({
            Data: [],
          });
        }
      } else {
        this.getOrdersData();
      }
    }
  };

  getOrderDownload = async (id) => {
    let res = await getApiAsynBlob(
      `orders/${id}/download`,
      cookies.get('authorization')
    );
    console.log('Download REs', res);
    //var blob = new Blob([res]);
    var blob = new Blob([res], { type: 'application/pdf' });

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'order.pdf';
    link.click();
  };
  getInvoiceDownload = async (id) => {
    let res = await getApiAsynBlob(
      `order/invoices/${id}/download`,
      cookies.get('authorization')
    );
    console.log('Download REs', res);
    //var blob = new Blob([res]);
    var blob = new Blob([res], { type: 'application/pdf' });

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'invoices.pdf';
    link.click();
  };

  getOrdersData = async () => {
    loadierShow('block');
    const { PageNumber, PageSize, key, sortby } = this.state;
    let res = await getApiAsyn(
      `orders?intent=load&paged=true&page=${PageNumber}&size=${PageSize}&sort=${key},${sortby}`,
      cookies.get('authorization')
    );

    if (res && res.status !== false) {
      istSearchList = res && res.page && res.page.total_elements;
      if (istSearchList > 0) {
        loadierShow('none');
        this.setState({
          Data: res._embedded.content,
          pageCount: istSearchList / PageSize,
        });
      } else {
        loadierShow('none');
        this.setState({
          Data: [],
          pageCount: '',
        });
      }
    } else {
      loadierShow('none');
    }
  };
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };
  handleResultperpage = (event) => {
    const {filterboll} = this.state;
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState(
      {
        [fieldName]: fleldVal,
      },
      () => {
        if(filterboll===false){
        this.getOrdersData();
        }
        else{
          this.getFilterOrder()
        }
      }
    );
  };

  handlePageClick = (data) => {
    let selected = data.selected + 1;
    let PageNumber = selected;
    const {filterboll} = this.state;
    this.setState({ PageNumber: PageNumber }, () => {

      if(filterboll===false){
        this.getOrdersData();
        }
        else{
          this.getFilterOrder()
        }
    });
  };

  isDeleteOrderAllowed = (access, item) => {
    return access?.orderMenu?.delete === true &&
            item.order_status != null &&
            item.order_status !== '' &&
            item.order_status.name !== 'Invoiced'
  }

  getDeleteButtonAction = (access, item) => {
    if (this.isDeleteOrderAllowed(access, item)) {
      return () => this.handleDeleteModal(item.id, item.order_status.name);
    }
    return noop;
  }

  getDeleteBtnStyles = (access, item) => {
    if (!this.isDeleteOrderAllowed(access, item)) {
      return { cursor: "not-allowed", color: "#0000007d" }
    }
    return {}
  }

  toggleOpen = (idx) => {
    this.setState((prevState) => {
      let { openRowIndex } = prevState;
      openRowIndex = prevState.openRowIndex === idx ? '' : idx;
      return { openRowIndex };
    });
  };
  async componentDidMount() {
    await loadingsShow('block');
    await this.getOrdersData();
    document.addEventListener('click', this.handleClickOutside, true);
    await loadingsShow('none');
  }
  componentWillUnmount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  renderCreateOrViewTripAction(item) {
    if (item.order_status.name === 'New') {
      return (<div className="vieworder" onClick={() => this.getTripByOrderId(item.id)} > 
                {' '} Create Trip
              </div>)
    } else {
      return (<Link
        className="vieworder"
        to={{ pathname: `/fleet/ordertrips/${item.id}/view`}}>
          {' '} View Trip
      </Link>
    )}
  }

  renderDeleteOrderAction(access, item) {
    const { id, order_status } = item;
    if (access?.orderMenu?.delete === true &&
        order_status != null && order_status !== '' && order_status.name !== 'Invoiced') {
        return (<div onClick={() => this.handleDeleteModal(id, order_status.name)}>
                  {' Delete Order'}
                </div>)
    }
    return (<div style={{ cursor: 'not-allowed', color: 'gray', opacity: 0.7 }}> Delete Order </div>)
  }

  render() {
    const {
      PageNumber,
      PageSize,
      tripCreationModal,
      pageCount,
      Data,
      deleteModal,
      textMessage,
      sort,
      filterboll,
      formOption,
      access,
      listData,
      bool,
      statusValue,
      key,
      sortby
    } = this.state;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />

        <div className="paddingbox">
          <div className="containerbox">
            <div className="title orderscreen">
              <h3>Orders</h3>

              <div className="rightbtn">
                <Button
                  type="button"
                  onClick={this.handleFilter}
                  className="default-button"
                >
                  {filterboll === false ? 'Filter' : 'Reset Filter'}
                </Button>
                <Link to="/fleet/order/add-new">
                  {' '}
                  <Button className="primary-button">+ NEW</Button>
                </Link>
              </div>
            </div>
            <Row>
              <div className="orderFilter">
                {filterboll ? (
                  <Filter
                    ReeferNane="reefer"
                    ReeferonChange={this.handleChangeGenChecked}
                    Reefervalue={sort.reefer}
                    UrgentNane="urgent"
                    UrgentonChange={this.handleChangeGenChecked}
                    Urgentvalue={sort.urgent}
                    OrderNoNane="order_no"
                    RefnoNane="refno"
                    RefnoonChange={this.handleChangeGen}
                    Refnovalue={sort.refno}
                    OrderNoonChange={this.handleChangeGen}
                    OrderNovalue={sort.order_no}
                    StartSelected={sort.date1}
                    StartNane="date1"
                    StartonChange={(date) => this.handleSortDate(date, 'date1')}
                    Startvalue={sort.date1}
                    EndSelected={sort.date2}
                    EndNane="date2"
                    EndonChange={(date) => this.handleSortDate(date, 'date2')}
                    Endvalue={sort.date2}
                    customervalue={sort.ids}
                    customerName="ids"
                    customeronChange={this.handleChangeGenCustomer}
                    bool={bool}
                    getAutosuggest={listData}
                    onClick={(id, name) => this.setAutosuggestId(id, name)}
                    OrderStatusname="order_status"
                    OrderStatusonChange={(val) =>
                      this.handleSortDropdonw(val, 'order_status')
                    }
                    OrderStatusvalue={sort.order_status}
                    LoadTypeName="load_type"
                    LoadTypeonChange={(val) =>
                      this.handleSortDropdonw(val, 'load_type')
                    }
                    LoadTypevalue={sort.load_type}
                    OrderTypeNane="order_type"
                    OrderTypeonChange={(val) =>
                      this.handleSortDropdonw(val, 'order_type')
                    }
                    OrderTypevalue={sort.order_type}
                    ComodityNane="comodity_type"
                    ComodityonChange={(val) =>
                      this.handleSortDropdonw(val, 'comodity_type')
                    }
                    Comodityvalue={sort.comodity_type}
                    EquipmentNane="eqp_type"
                    EquipmentonChange={(val) =>
                      this.handleSortDropdonw(val, 'eqp_type')
                    }
                    Equipmentvalue={sort.eqp_type}
                    AddressNane="address"
                    AddressonChange={this.handleChangeGen}
                    Addressvalue={sort.address}
                  />
                ) : null}
              </div>
              {istSearchList > 0 ? (
                <Col md={12} className="odrlist">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th class={key==='order_no' && sortby==='asc' ? 'active cursor' : key==='order_no' && sortby==='desc' ? 'active cursor' :'cursor'} colSpan="2" onClick={()=>this.handleSortBy('order_no')}>Order No
                        <span class={key==='order_no' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='order_no' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        
                        </th>
                        <th>Billed To</th>
                        <th class={key==='order_date' && sortby==='asc' ? 'active cursor' : key==='order_date' && sortby==='desc' ? 'active cursor' :'cursor'} onClick={()=>this.handleSortBy('order_date')}>Date
                        <span class={key==='order_date' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='order_date' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>
                        <th>First Pickup Date</th>
                        <th class={key==='urgent' && sortby==='asc' ? 'active cursor' : key==='urgent' && sortby==='desc' ? 'active cursor' :'cursor'} onClick={()=>this.handleSortBy('urgent')}>Urgent 
                        <span class={key==='urgent' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='urgent' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>
                        <th>First pick up city</th>
                        <th class={key==='order_status' && sortby==='asc' ? 'active cursor' : key==='order_status' && sortby==='desc' ? 'active cursor' :'cursor'} onClick={()=>this.handleSortBy('order_status')}>Order Status
                        <span class={key==='order_status' && sortby==='asc' ? 'active p-sortable-column-icon pi pi-fw  pi-sort-amount-up-alt' : key==='order_status' && sortby==='desc' ? 'active  p-sortable-column-icon pi pi-fw  pi-sort-amount-down-alt' :'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
                        </th>

                        <th>Load Type</th>
                        <th>Order Type</th>

                        <th>Equipment</th>
                        <th>PO/Ref No</th>
                        <th>Total</th>
                        <th>Invoice</th>
                        <th colSpan="3">Actions</th>
                      </tr>
                    </thead>
                    {Data.map((item, i) => (
                      <tbody
                        key={item.id}
                        className={
                          item.order_status &&
                          item.order_status.name
                        }
                      >
                        <tr
                          className={
                            this.state.openRowIndex === i ? 'parentactive' : ''
                          }
                        >
                          <td
                            className={
                              this.state.openRowIndex === i
                                ? 'minus cursorpoit'
                                : 'plus cursorpoit'
                            }
                            onClick={() => this.toggleOpen(i)}
                          >
                            +
                          </td>
                          <td>{item.order_no}</td>
                          <td>{item.billed_to.name}</td>
                          <td>
                            {item.order_date != null && item.order_date !== ''
                              ? item.order_date
                              : 'N/A'}
                          </td>
                          <td>
                            {item.pickup != null && item.pickup !== ''
                              ? item.pickup
                              : 'N/A'}
                          </td>
                          <td>{item.urgent ? "Yes" : "No"}</td>
                          <td>
                            {item.first_pickup_city != null && item.first_pickup_city !== ''
                              ? item.first_pickup_city
                              : 'N/A'}
                          </td>
                          <td>
                            {item.order_status != null &&
                            item.order_status !== ''
                              ? item.order_status.name
                              : 'N/A'}
                          </td>
                          <td>
                            {item.load_type != null && item.load_type !== ''
                              ? item.load_type
                              : 'N/A'}
                          </td>
                          <td>
                            {item.order_type != null && item.order_type !== ''
                              ? item.order_type.name
                              : 'N/A'}
                          </td>
                          <td>
                            {item.equip_type != null && item.equip_type !== ''
                              ? item.equip_type.name
                              : 'N/A'}
                          </td>
                          <td>{item.ref_no}</td>
                          <td>
                            {item.order_cur != null &&
                            item.order_cur !== '' &&
                            item.order_cur.key === 1
                              ? '$'
                              : 'C$'}{' '}
                            {item.total != null && item.total !== ''
                              ? item.total
                              : 'N/A'}
                          </td>
                          <td>
                            {item.invoice != null && item.invoice !== '' ? (
                              <span
                                className="downicon"
                                onClick={() =>
                                  this.getInvoiceDownload(item.invoice)
                                }
                              >
                                Download
                              </span>
                            ) : (
                              'N/A'
                            )}
                          </td>
                          <td>
                            <div className="moremenus">
                              <img src={default_menu} alt="More" />
                              <ul>
                                <li onClick={() => { this.props.history.push(`/fleet/order/${item.id}/edit`)  }}  >
                                  {' '} Edit Order
                                </li>
                                <li>{this.renderDeleteOrderAction(access, item)}</li>
                                <li> {this.renderCreateOrViewTripAction(item)} </li>
                                <li onClick={() => this.getOrderDownload(item.id)} >
                                  Download Order 
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>

                        <tr
                          className={
                            this.state.openRowIndex === i
                              ? 'subactive'
                              : 'subhide'
                          }
                        >
                          <td colSpan="18">
                            <Table bordered hover>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Location</th>
                                  <th>Status</th>
                                  <th>Request Date</th>
                                  <th>Weights</th>
                                  <th>Unit</th>
                                  <th>Appt</th>
                                  <th>Info</th>
                                  <th>Note</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.items.map((x) => (
                                  <tr key={x.id.toString()}>
                                    <td>{x.type}</td>

                                    <td>
                                      <span>
                                        <strong>{x.loc_nm}</strong>
                                      </span>
                                      <br></br>
                                      {x.location}
                                    </td>
                                    <td>
                                      {x.item_status && x.item_status.name}
                                    </td>
                                    <td>{x.req_date}</td>
                                    <td>{x.weight}</td>
                                    <td>{x.unit}</td>
                                    <td>{x.apnt_type && x.apnt_type.name}</td>
                                    <td>{x.info}</td>
                                    <td>{x.note}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>

                  <div className="paginationData">
                    <div className="leftpage">
                      <div className="showing">
                        <span>Showing: </span>
                        {PageSize * PageNumber + 1 - PageSize} -
                        {PageSize > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber > istSearchList
                          ? istSearchList
                          : PageSize * PageNumber}{' '}
                        <span> of</span> {istSearchList} <span>entries. </span>{' '}
                      </div>

                      <div className="rowperpage">
                        <Form.Group>
                          <Form.Label>Showing rows:</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleResultperpage}
                            name="PageSize"
                            value={PageSize}
                          >
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>50</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />

                    <div className="clr"></div>
                  </div>
                </Col>
              ) : istSearchList === 0 ? (
                <div className="norecord">No record found</div>
              ) : null}
            </Row>
          </div>
        </div>

        <Popup
          isOpen={deleteModal}
          onConfirm={statusValue === 'New' ? this.handleRecordDelete : null}
          textMessage={textMessage}
          toggleShow={() => this.setState((prevState) => ({ deleteModal: !prevState.deleteModal }))}
        />

        <ModalPopup
          headerTitle="Are you sure you want to create trip with this order"
          isOpen={tripCreationModal}
          actionLabel="OK"
          cancelLable="Cancel"
          onConfirm={this.handleCreateTrip}
          textMessage="Start creating trip click OK button"
          toggleShow={this.handleTripCreationModal}
        />
      </div>
    );
  }
}

export default Orders;
