import Moment from 'moment';
//Rest API Env
let baseUrlSamsara = 'https://api.samsara.com';
let baseURLSMS = 'https://prov.ombrex.net/ombrexplus/';

//export let baseUrl = 'http://158.69.1.152:8383/v2/';

//export let baseUrl = 'https://api.easyonroad.com/v2/';
export let baseUrl = 'https://easyonroad.com/api/v2/';

// export let baseUrl = 'https://uat-api.ezonroad.com/api/v2/';
// export let baseUrl = 'https://api.ezonroad.com/api/v2/'


console.log("deployment 4");

export let baseUrls = baseUrl;
// post API services
function loadingShow(hidden) {
  let loading = document.getElementById('loading');
  loading.style.display = hidden;
}

export const loadierShow = (hidden) => {
  let loading = document.getElementById('loading');
  loading.style.display = hidden;
};
export function postApiWithoutReq(type) {
  loadingShow('block');
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },

    method: 'POST',
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
}

export const postApiWithoutReqAsyn = async (type) => {
  //export function postApiWithoutReqAsyn(type) {
  return await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },

    method: 'POST',
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      return responseJson.json();
    })
    .catch((error) => {
      throw error;
    });
};

// Post without body
export function postApiFormData(type, UserData, token) {
  loadingShow('block');
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: token,
    },

    method: 'POST',
    body: UserData,
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        //window.location.replace("/");
        return response;
      }
      loadingShow('none');
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');

      return responseJson.json();
    })
    .catch((error) => {
      loadingShow('none');
      throw error;
    });
}

export function postApi(type, UserData, token) {
  loadingShow('block');
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },

    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        //window.location.replace("/");
        return response;
      }
      loadingShow('none');
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');

      return responseJson.json();
    })
    .catch((error) => {
      loadingShow('none');
      throw error;
    });
}

export function postApiLogin(type, UserData) {
  loadingShow('block');
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },

    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
        loadingShow('none');
        //window.location.replace("/");
        return response;
      }
      loadingShow('none');
      return response;
    })
    .then((responseJson) => {
      loadingShow('none');

      //return responseJson.json()
      return responseJson.headers.get('authorization');
    })
    .catch((error) => {
      loadingShow('none');
      throw error;
    });
}

//post API services delete with body
export function deleteApiBody(type, dataToPost) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'DELETE',
    body: JSON.stringify(dataToPost),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
    })
    .then((responseJson) => {})
    .catch((error) => {
      throw error;
    });
  // });
}

//post API services delete with json response
export function deleteApiOut(type, dataToPost) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'DELETE',
    body: JSON.stringify(dataToPost),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        return responseJson;
      } else {
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
}
export function deleteApiOutRes(type, dataToPost) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'DELETE',
    body: JSON.stringify(dataToPost),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        return responseJson.json();
      } else {
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
}
//PUT API services
export function putApi(type, UserData,token) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': token
    },
    method: 'PUT',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {})
    .catch((error) => {
      throw error;
    });
  // });
}

//PUT API services Response
export function putApiRes(type, UserData, token, showLoader = true) {
  if (showLoader) {
    loadingShow('block');
  }
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'PUT',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
        if (showLoader) {
          loadingShow('none');
        }  
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        showLoader && loadingShow('none');
        return responseJson;
      } else {
        // loadingShow('none');
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
  // });
}

//post API servicest without json Response
export function postApiLiteral(type, UserData) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Authorization': token
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }
      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        return responseJson;
      } else {
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteApi(type, token) {
  return fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'DELETE',
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.replace('/');
      }

      return response;
    })
    .then((responseJson) => {
      if (responseJson.status === 200) {
        return responseJson;
      } else {
        return responseJson.json();
      }
    })
    .catch((error) => {
      throw error;
    });
}

export const formatDate = (date) => {
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  const formattedDate = `${day}-${month}-${year} ${time}`;

  return formattedDate;
};

export const getFormattedDate = (
  date,
  dateFormat = 'dd-MM-YYYY hh:mm:ss',
  toString = false
) => {
  if (date) {
    const formattedDate = Moment(date, dateFormat);
    return toString ? formattedDate.format(dateFormat) : formattedDate;
  }

  return '';
};
export const getFormattedDateNull = (
  date,
  dateFormat = 'dd-MM-YYYY hh:mm:ss',
  toString = false
) => {
  if (date) {
    const formattedDate = Moment(date, dateFormat);
    return toString ? formattedDate.format(dateFormat) : formattedDate;
  }

  return null;
};
export const putApiAsyn = async (type, UserData, token) => {
  loadingShow('block');
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const postApiAsyn = async (type, UserData, token, extraProps) => {
  const { showPageBlockingLoader } = extraProps || {};
  if (showPageBlockingLoader || !extraProps) {
    loadingShow('block');
  }

  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const getApiAsyn = async (type, token, shouldPrependBaseUrl = true) => {
  const url = shouldPrependBaseUrl ? baseUrl + type : type;
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      if (response.status === 400) {
        throw new Error(response)
      }
      return response.json();
    }, (error) => {
      console.log("ERRoR XX", error )
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      console.log(error, "XXX")
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};

export const validationForSpecialchar = (e) => {
  var result = /[^a-z 0-9 A-Z\\,\\.\\;]/g;
  if (!result.test(e)) {
    return false;
  }
  return true;
};
export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const deleteApiAsyn = async (type, UserData, token) => {
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'DELETE',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const postApiLoginAsyn = async (type, UserData) => {
  loadingShow('block');
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      //let resobjec = response.json();
      //  resobjec = resobjec["token"] = response.headers.get('authorization')
      return response;
    })
    .then((data) => {
      data['authorization'] = data.headers.get('authorization');
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const formDataApiAsyn = async (type, UserData, token) => {
  loadingShow('block');
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'multipart/form-data',
      //'Accept': 'application/json',
      Authorization: token,
    },
    method: 'POST',
    body: UserData,
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const formatDateyyyyMMdd = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getApiAsynBlob = async (type, token) => {
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/zip',
      Accept: 'application/zip',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.blob();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};


export const getApiAsynBlobZip = async (type, token) => {
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/zip',
      Accept: 'application/zip',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.blob();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const getApiAsynBlobImage = async (type, token) => {
  let result = await fetch(type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const getApiAsynS3Images = async (type) => {
  // loadingShow('block');
  let result = await fetch(type, {
    method: 'GET',
    'Access-Control-Allow-Origin': '*',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.blob();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const formatDateMMDDYY = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
};

export const formatDateOrder = (
  date,
  dateFormat = 'dd-MM-YYYY hh:mm:ss',
  toString = false
) => {
  if (date) {
    const formattedDate = Moment(date, dateFormat);
    return toString ? formattedDate.format(dateFormat) : formattedDate;
  }

  return '';
};

export const putApiAsynGoogle = async (type) => {
  loadingShow('block');
  let result = await fetch(type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const putApiAsyns = async (type, UserData, token, extraProps) => {
  const { showPageBlockingLoader } = extraProps || {};
  if (showPageBlockingLoader || !extraProps) {
    loadingShow('block');
  }
  
  let result = await fetch(baseUrl + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'PUT',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const numberInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 2) return `${currentValue.slice(0, 2)}`;
  if (cvLength > 2) return `${currentValue.slice(0, 2)}`;
};

export const formatCurrency = (num) => {
  return '$ ' + Number(num.toFixed(2)).toLoceString() + ' ';
};
export const formatCurrencyToDecimal = (num) => {
  return '$ ' + num.toFixed(2);
};

export const getDateFormattedData = (d) => {
  let m = new Date(d);
  var dateString =
    m.getUTCFullYear() +
    '/' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '/' +
    ('0' + m.getUTCDate()).slice(-2) +
    ' ' +
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + m.getUTCSeconds()).slice(-2);

  console.log(dateString);
};

export const loadingsShow = (hidden) => {
  let loading = document.getElementById('loadings');
  loading.style.display = hidden;
};

export const smsAuth = async (type, UserData) => {
  loadingShow('block');
  let result = await fetch(baseURLSMS + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        loadingShow('none');
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const sendSMSPost = async (type, UserData, token) => {
  loadingShow('block');
  let result = await fetch(baseURLSMS + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'POST',
    body: JSON.stringify(UserData),
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        loadingShow('none');
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};

export const getSMSPost = async (type, token) => {
  loadingShow('block');
  let result = await fetch(baseURLSMS + type, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
        loadingShow('none');
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;
      if (error.TypeError) {
        error['Message'] = 'Internal server error (' + error.TypeError + ')';
      }
      return error;
    });
  return result;
};
export const getApiAsynSamrasa = async (type, token) => {
  const url = baseUrlSamsara + type;
  let result = await fetch(url, {
    headers: {
      Accept: 'Accept: application/json',
      Authorization: 'Bearer ' + token,
    },
    method: 'GET',
  })
    .then((response) => {
      loadingShow('none');
      if (response.status === 401) {
      }
      return response.json();
    })
    .then((data) => {
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};

export const getApiAsyn1 = async (type, token, shouldPrependBaseUrl = true) => {
  loadingShow('block');
  const url = shouldPrependBaseUrl ? baseUrl + type : type;
  let result = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token,
    },
    method: 'GET',
  })
    .then((response) => {
      // loadingShow('none');
      if (response.status === 400 || response.status === 403) {
        throw new Error(response)
      }
      return response
    }, (error) => {
      console.log("ERRoR XX", error )
    })
    .then((data) => {
      data['authorization'] = data.headers.get('authorization');
      data['APIResult'] = 1;
      return data;
    })
    .catch((error) => {
      console.log(error, "XXX")
      loadingShow('none');
      error['APIResult'] = 0;

      error['Message'] = 'Internal server error (' + error.TypeError + ')';
      error = {
        Message: 'Internal server error (' + error.TypeError + ')',
        type: 'error',
      };
      return error;
    });
  return result;
};
